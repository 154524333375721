
<div class="max-h-[50vh] max-w-[75vw] min-w-[50vw] overflow-none">
    <div class="overflow-hidden rounded-lg bg-white shadow-xl">
        <div class="flex justify-between m-4">
            <div>
                <h3 class="text-lg font-medium text-secondary-900">{{data?.title || 'Filter'}}</h3>
            </div>
            <div class="flex gap-x-4">
                <div class="cursor-pointer hover:text-ternary-700" (click)="selectionActionForAllFilter(true)">Select all</div>
                <div class="cursor-pointer hover:text-ternary-700" (click)="selectionActionForAllFilter(false)">Select none</div>
            </div>
        </div>
        <div class="m-4">
            <input [formControl]="searchText" class="searchBox w-full rounded border-gray-300 col-span-1"
             placeholder="Search"/>
        </div>
        <div class="overflow-auto max-h-[35vh] m-4">
            <div *ngFor="let columnFormGroup of valueForm.controls | search: searchText.value:'flex-report-options':['column']">
                <div class="flex items-center my-2">
                    <input [formControl]="columnFormGroup.controls.selected" [id]="'column-'+columnFormGroup.value.column" type="checkbox" value="" class="h-4 w-4 rounded border-gray-300 text-primary-600 shadow-sm focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 focus:ring-offset-0 disabled:cursor-not-allowed">
                    <label [for]="'column-'+columnFormGroup.value.column" class="ms-2 text-gray-700 break-all">{{columnFormGroup.value.column}}</label>
                </div>
            </div>
        </div>
        <div class="flex justify-end m-4">
            <div class="flex items-center space-x-4">
                <prism-button [additionalStyles]="BUTTON_STYLES.GHOST"
                        [buttonStyle]="BUTTON_STYLES.GHOST" name="Cancel" (onClick)="close(data.filterAppliedValues)" />
                <prism-button [additionalStyles]="BUTTON_STYLES.ICON"
                        [buttonStyle]="BUTTON_STYLES.PLAIN_CYAN" name="Apply" (onClick)="applyFilter()" />
            </div>
        </div>
    </div>
</div>
