export const ALL_ACCOUNT: { [key in string]: string } = {
  "prism": "0",
  "payer_account_id": "0",
  "external_id": "0",
  "model_id": "0",
  "id": "all_accounts",
  "customer_name": "All",
  "linked_account_name": "All"
}


export const CLOUD_CONFIGURATION = {
  CLOUD: ['AWS', 'Azure'],
  TYPE: [
    { name: 'Reports - Cloud save', key: 'REPORT_CLOUD_SAVE' }
  ]
}