import {Action, Selector, State, StateContext} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {UpdateSidebarStatus} from "./app.action";

interface IAppStore {
    sidebarStatus: boolean;
}

@State<IAppStore>({
    name: 'AppState',
    defaults: {
        sidebarStatus: false
    }
})
@Injectable()
export class AppStore {
    @Selector()
    static getSidebarStatus(state: IAppStore) {
        return state.sidebarStatus
    }

    @Action(UpdateSidebarStatus)
    updateSidebarStatus({getState, patchState}: StateContext<IAppStore>, action: UpdateSidebarStatus) {
        const state = getState()
        patchState({...state, sidebarStatus: action.payload})
    }
}
