import { Action, Select, Selector, State, StateContext } from '@ngxs/store';
import { IChartDataSet } from '../../interface/aws-summary.interface';
import { Injectable } from '@angular/core';
import {
  AddAWSSummaryChartData,
  AddOverallSavingTrend,
  AddPrismaCoverage,
  ResetDashboardStore,
  AddAwsMarketplace,
  AddAwsMarketplaceItemizedSpendData,
} from './dashboard.action';
import { IDrillDownTableData } from '../../interface/table.interface';

interface IDashboardStore {
  awsSummaryChart: IChartDataSet | null;
  prismCoverage: IChartDataSet | null;
  savingTrend: IChartDataSet | null;
  awsMarketPlace: IChartDataSet | null;
  awsMarketPlaceItemizedSpend: IDrillDownTableData | null;
}

@State<IDashboardStore>({
  name: 'DashboardState',
  defaults: {
    awsSummaryChart: null,
    prismCoverage: null,
    savingTrend: null,
    awsMarketPlace: null,
    awsMarketPlaceItemizedSpend: null,
  },
})
@Injectable()
export class DashboardState {
  @Selector()
  static getPrismCoverageMetric(state: IDashboardStore): number {
    return state.prismCoverage?.currentMonthPrismCoverageKPI || -1;
  }

  @Selector()
  static getKpis(state: IDashboardStore) {
    return [
      {
        label: 'Current month',
        icon: '/assets/icons/metrics.svg',
        amount: state.savingTrend?.currentMonthCost,
      },
      {
        label: 'Last month',
        icon: '/assets/icons/dollar.svg',
        amount: state.savingTrend?.lastMonthCost,
      },
      {
        label: 'Projected cost for month',
        icon: '/assets/icons/metrics.svg',
        amount: null,
      },
      {
        label: 'Current month PRISM coverage',
        icon: '/assets/icons/metrics.svg',
        amount: state.prismCoverage?.currentMonthCost,
      },
    ];
  }

  @Action(AddAWSSummaryChartData)
  addAWSSummaryChartData(
    { getState, patchState }: StateContext<IDashboardStore>,
    action: AddAWSSummaryChartData,
  ) {
    const state = getState();
    patchState({ ...state, awsSummaryChart: action.payload });
  }

  @Action(AddPrismaCoverage)
  addPrismCoverageChartData(
    { getState, patchState }: StateContext<IDashboardStore>,
    action: AddAWSSummaryChartData,
  ) {
    const state = getState();
    patchState({ ...state, prismCoverage: action.payload });
  }

  @Action(AddOverallSavingTrend)
  addOverallSavingTrend(
    { getState, patchState }: StateContext<IDashboardStore>,
    action: AddAWSSummaryChartData,
  ) {
    const state = getState();
    patchState({ ...state, savingTrend: action.payload });
  }

  @Action(AddAwsMarketplace)
  addAwsMarketPlace(
    { getState, patchState }: StateContext<IDashboardStore>,
    action: AddAWSSummaryChartData,
  ) {
    const state = getState();
    patchState({ ...state, awsMarketPlace: action.payload });
  }

  @Action(AddAwsMarketplaceItemizedSpendData)
  addAwsMarketPlaceItemizedSpend(
    { getState, patchState }: StateContext<IDashboardStore>,
    action: AddAwsMarketplaceItemizedSpendData,
  ) {
    const state = getState();
    patchState({ ...state, awsMarketPlaceItemizedSpend: action.payload });
  }

  @Action(ResetDashboardStore)
  resetStore({ patchState }: StateContext<IDashboardStore>) {
    patchState({
      awsSummaryChart: null,
      prismCoverage: null,
      savingTrend: null,
      awsMarketPlaceItemizedSpend: null,
    });
  }
}
