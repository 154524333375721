<div
  class="prism--dropdown-container"
  #prismDropDownContainer
>
  <div class="dropdown-trigger" prismDropdown>
    <ng-content select="[trigger]" />
  </div>
  <div class="prism--dropdown-content"
       #prismDropDownContent
  >
    <ng-content select="[content]" />
  </div>
</div>
