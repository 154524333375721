import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { UserStore } from '../../store/user/user.store';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { IOrganization } from '../../interface/user.interface';
import { FEATURE_FLAG } from '../../enums/featureFlag.enum';
import { Router } from '@angular/router';

@Directive({
  selector: '[featureFlag]',
})
export class FeatureFlagDirective implements OnInit {
  @Input('featureFlag') featureFlag: FEATURE_FLAG[] = [];

  @Select(UserStore.getSelectedOrganization) selectedOrganization$!: Observable<IOrganization>;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<unknown>,
    private router: Router
  ) {}

  ngOnInit() {
    
    this.selectedOrganization$.subscribe((res) => {
      if(this.featureFlag.map(flag => res.featureFlags.includes(flag)).includes(false)) {
        this.viewContainerRef.clear();
        // this.router.navigate(['/prism/dashboard']);
      } else {
        this.viewContainerRef.clear();
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      }
    });
  }
}
