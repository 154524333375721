import {Pipe, PipeTransform} from '@angular/core';
import {amountTransform} from "../../utils/chart.util";

@Pipe({
  name: 'million',
})
export class AmountPipe implements PipeTransform {

  constructor() { }

  transform(value: number): any {
    if(value) {
      return amountTransform(value)
    }
    return value;
  }

}

