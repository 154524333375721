<div
  class="flex justify-between my-4"
  *ngIf="drillNavigationSegments.length > 0 || filters.length > 0"
>
  <div>
    <div class="line-clamp-1" *ngIf="drillNavigationSegments.length > 0">
      <ng-container *ngFor="let segment of drillNavigationSegments">
        /&nbsp;<a
          prismTooltip="{{ segment.tooltip }}"
          placement="top"
          [delay]="500"
          (click)="drillPath = segment.drillUpPath; drillDown()"
          class="hover:text-ternary-700"
          >{{ segment.path }}</a
        >&nbsp;
      </ng-container>
    </div>
  </div>
  <div>
    <div *ngIf="filters.length > 0">
      <prism-button
        name="Reset Filter"
        [additionalStyles]="'w-full justify-center'"
        [buttonStyle]="BUTTON_STYLES.GHOST"
        (click)="resetAllFilters()"
      >
        <ng-icon name="bootstrapBootstrapReboot" />
      </prism-button>
    </div>
  </div>
</div>
<div *ngIf="data && isAvailableForExport" class="w-full flex justify-end my-4">
  <prism-button
    name="Export as CSV"
    [additionalStyles]="'w-full justify-center'"
    [buttonStyle]="BUTTON_STYLES.GHOST"
    (click)="exportToCsvHandlerFunction()"
  >
    <ng-icon name="bootstrapDownload" />
  </prism-button>
</div>
<div
  class="max-h-[75vh] w-full overflow-scroll rounded border border-gray-200"
  *ngIf="data"
>
  <table
    class="w-full border-collapse bg-white text-left text-gray-500 rounded-md"
    *ngIf="data"
  >
    <thead class="bg-gray-100/80 backdrop-blur-md sticky top-0" [class]="tableData?.headerRowClass">
      <tr>
        <th
          scope="col"
          *ngFor="let header of tableData?.headers || data?.headers"
          [class]="header.class"
          class="px-6 py-4 font-medium text-gray-900 border-r-[1px] last:border-r-0"
        >
          <div class="flex justify-between items-center text-center">
            <div>
              <span
                [class.cursor-pointer]="header.allowSort"
                [class]="header.spanClass"
                (click)="header.allowSort && sortByColumn(header.text)"
              >
                {{ header.text }}
                <span
                  *ngIf="header.text == sortedColumn && sortDirection == 1"
                  class="px-2"
                  >&uarr;</span
                >
                <span
                  *ngIf="header.text == sortedColumn && sortDirection == -1"
                  class="px-2"
                  >&darr;</span
                >
              </span>
            </div>
            <div
              prismTooltip="{{ 'Filter ' + header.text }}"
              placement="top"
              [delay]="500"
              (click)="filter(header.text)"
              *ngIf="header.allowFilter"
              class="cursor-pointer flex items-center text-center justify-center"
            >
              <ng-icon
                class="text-gray-300 hover:text-gray-500"
                [name]="
                  isFilteredHeader(header.text)
                    ? 'bootstrapFunnelFill'
                    : 'bootstrapFunnel'
                "
              />
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody class="divide-y divide-gray-100 border-t border-gray-100 text-sm">
      <tr
        *ngIf="drillPath.length > 0"
        (click)="drillUp()"
        class="hover:bg-gray-50 cursor-pointer"
      >
        <ng-container
          *ngFor="let header of tableData?.headers || data?.headers; index as i"
        >
          <td
            *ngIf="i == 0"
            class="px-6 py-4 max-w-sm break-words hover:text-ternary-700"
          >
            <div class="flex items-center">
              <span class="mx-4 flex items-center">
                <ng-icon name="bootstrapFolder" />
              </span>
              <span>. . .</span>
            </div>
          </td>
          <td *ngIf="i != 0"></td>
        </ng-container>
      </tr>
      <ng-container
        *ngFor="
          let row of tableData?.data || data?.data
            | search: searchTableText : 'table' : searchableHeaders
        "
      >
        <tr (click)="selectRow(row)" class="hover:bg-gray-50 cursor-pointer">
          <ng-container
            *ngFor="
              let header of tableData?.headers || data?.headers;
              index as index
            "
          >
            <td
              *ngIf="
                (!header.isColspan && !header.isRowspan) ||
                ((header.isColspan || header.isRowspan) &&
                  (row[header.text].rowspan || row[header.text].colspan))
              "
              [class]="row[header.text]?.class || ''"
              [attr.rowspan]="row[header.text]?.rowspan || 1"
              [attr.colspan]="row[header.text]?.colspan - 1 || 1"
              class="px-6 py-4 max-w-sm break-words"
              (hover)="
                row[header.text]?.onHoverFn && row[header.text].onHoverFn()
              "
              (click)="
                row[header.text]?.onClickFn && row[header.text].onClickFn()
              "
            >
              <div class="flex items-center">
                <span
                  *ngIf="row.nestedTable && index == 0"
                  (click)="row.expanded = !row.expanded"
                  class="px-4 flex items-center"
                  [class.hover:text-ternary-700]="row.nestedTable"
                >
                  <ng-icon
                    name="bootstrapChevronDown"
                    [class.rotate-180]="row.expanded"
                    class="transform transition-transform ease-in"
                  />
                </span>
                <span
                  *ngIf="
                    row[header.text]?.icon &&
                    row[header.text]?.icon.iconPosition == 'before'
                  "
                  class="pr-4 flex items-center"
                >
                  <ng-icon
                    [name]="row[header.text]?.icon?.name"
                    [class]="row[header.text]?.icon?.class"
                  />
                </span>
                <span
                  *ngIf="row[header.text]?.isDirectory"
                  class="px-4 flex items-center"
                >
                  <ng-icon name="bootstrapFolder" />
                </span>
                <span
                  *ngIf="header.type == 'date'"
                  [class]="row[header.text]?.spanClass || ''"
                  >{{ (row[header.text]?.value | date) || "" }}</span
                >
                <span
                  *ngIf="header.type == 'datetime'"
                  [class]="row[header.text]?.spanClass || ''"
                  >{{
                    (row[header.text]?.value | date: "MMM d, y, h:mm a") || ""
                  }}</span
                >
                <span
                  *ngIf="header.type == 'currency'"
                  [class]="row[header.text]?.spanClass || ''"
                  >{{
                    (row[header.text]?.value
                      | currency: "USD" : "symbol" : "1.2-2") || ""
                  }}</span
                >
                <span
                  *ngIf="header.type == 'number'"
                  [class]="row[header.text]?.spanClass || ''"
                  >{{ (row[header.text]?.value | number: "1.1-2") || "" }}</span
                >
                <span
                  *ngIf="header.type == 'string'"
                  [class]="row[header.text]?.spanClass || ''"
                  class="type_string"
                  >{{
                    [
                      "month",
                      "Month",
                      "YearMonth",
                      "yearMonth",
                      "Yearmonth",
                      "yearmonth",
                      "Year Month",
                      "year Month",
                      "Year month",
                      "year month",
                      "period",
                      "Period"
                    ].includes(header.text)
                      ? getReadableYearMonth(
                          row[header.text]?.value,
                          header.text
                        )
                      : row[header.text]?.value || ""
                  }}</span
                >
                <span
                  *ngIf="header.type == 'button'"
                  [class]="row[header.text]?.spanClass || ''"
                  class="flex justify-center items-center gap-4"
                >
                  <div *ngFor="let button of data.button">
                    <prism-button
                      [name]="button.name"
                      [additionalStyles]="'w-full justify-center'"
                      [buttonStyle]="button.style"
                      (click)="onButtonClick(row, button.name)"
                    >
                      {{ button.iconHtml }}
                    </prism-button>
                  </div>
                </span>
                <span
                  *ngIf="
                    row[header.text]?.icon &&
                    row[header.text]?.icon.iconPosition == 'after'
                  "
                  class="pl-4 flex items-center"
                >
                  <ng-icon
                    [name]="row[header.text]?.icon?.name"
                    [class]="row[header.text]?.icon?.class"
                  />
                </span>
              </div>
            </td>
          </ng-container>
        </tr>
        <tr *ngIf="row.nestedTable && row.expanded">
          <td
            [attr.colspan]="tableData?.headers.length || data?.headers.length"
          >
            <div class="ml-16 m-4">
              <prism-drill-down-table [data]="row.nestedTable" />
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
