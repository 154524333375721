import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxsModule } from '@ngxs/store';
import { DashboardState } from './store/dashboard/dashboard.store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { LoginComponent } from './pages/login/login.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UserStore } from './store/user/user.store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { AppStore } from './store/app/app.store';
import { AuthInterceptor } from './auth/auth.interceptor';
import { SharedModule } from './modules/shared/shared.module';
import { CreateUserComponent } from './pages/login/create-user/create-user.component';
import { PrismInputComponent } from './components/core/prism-input/prism-input.component';
import { PrismButtonComponent } from './components/core/prism-button/prism-button.component';
import { PrismTextAreaComponent } from './components/core/prism-text-area/prism-text-area.component';
import { ModalComponent } from './components/modal/modal.component';
import { ConfirmationModalComponent } from './components/modal/confirmation-modal/confirmation-modal.component';
import { environment } from '../environments/environment';
import { TableFilterModalComponent } from './components/modal/table-filter-modal/table-filter-modal.component';
import { NgOptimizedImage } from '@angular/common';
import { PrismFieldErrorComponent } from './components/core/prism-field-error/prism-field-error.component';
import { AccountRecoveryComponent } from './pages/login/account-recovery/account-recovery.component';
import { InformationModalComponent } from './components/modal/information-modal/information-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginFormComponent,
    CreateUserComponent,
    ModalComponent,
    ConfirmationModalComponent,
    TableFilterModalComponent,
    AccountRecoveryComponent,
    InformationModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxsModule.forRoot([DashboardState, UserStore, AppStore]),
    environment?.STAGE != 'prod' ? NgxsLoggerPluginModule.forRoot() : [],
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot(),
    ReactiveFormsModule,
    SharedModule,
    PrismInputComponent,
    PrismButtonComponent,
    PrismTextAreaComponent,
    NgOptimizedImage,
    PrismFieldErrorComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
