import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Auth } from 'aws-amplify';
import { Router } from '@angular/router';
import { UserService } from '../../services/user/user.service';
import { Store } from '@ngxs/store';
import { AddUserDetails } from '../../store/user/user.action';
import { NGXLogger } from 'ngx-logger';
import { LocalStorageUtil } from '../../utils/local-storage.utl';
import { environment } from '../../../environments/environment';
import { ToastService } from 'src/app/services/toast/toast.service';
import { TOAST_TYPE } from 'src/app/enums/toastType.enum';

@Component({
  selector: 'prism-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent {
  invalid: boolean = false;
  loginForm!: FormGroup;
  isLoading: boolean = false;
  loginMessage: string | null = null;
  isPasswordLoginEnabled: boolean = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private store: Store,
    private logger: NGXLogger,
    private toastService: ToastService
  ) {
    this.constructLoginForm();
    localStorage.clear();
  }

  ngOnInit() {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        this.isLoading = true;
        this.loginMessage = `Retrieving User Details..`;
        const email = user.attributes.email;
        this.loginForm.get('email').setValue(email);
        this.checkAndRegisterSSOUser(email);
      })
      .catch(() => {
        console.log('No Authenticated User Found');
      });
  }

  constructLoginForm() {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
    });
    LocalStorageUtil.clearAllItemsWithKeyword('prism-');
  }

  async login() {
    try {
      const { email } = this.loginForm.getRawValue();
      if (email.includes('@presidio.com')) {
        this.emailIncludesPresidio();
      } else {
        this.emailNotIncludespresidio();
      }
    } catch (e: { name: string; message: string } | any) {
      this.loginMessage = null;
      this.loginForm.setErrors({
        loginError: e.message || 'Something went wrong',
      });
      this.isLoading = false;
    }
  }
  async emailIncludesPresidio() {
    this.loginForm.get('password')?.removeValidators([Validators.required]);
    this.loginForm.get('password')?.updateValueAndValidity();
    this.loginForm.markAllAsTouched();
    this.loginForm.setErrors(null);
    if (this.loginForm.valid) {
      this.isLoading = true;
      this.loginMessage = `Validating email..`;
      await Auth.federatedSignIn({
        customProvider: `prism-${environment.STAGE}`,
      });
    }
  }

  async emailNotIncludespresidio() {
    this.isPasswordLoginEnabled = true;
    // let initTempPasswordLogin = await this._checkInitialTempPasswordLogin();
    // if(initTempPasswordLogin) return;
    this.loginForm.markAllAsTouched();
    this.loginForm.setErrors(null);
    if (this.loginForm.valid) {
      this.isLoading = true;
      this.loginMessage = `Validating credentials..`;
      const { email, password } = this.loginForm.getRawValue();
      let AuthResponse = await Auth.signIn(email, password);
      if(AuthResponse.challengeName == "NEW_PASSWORD_REQUIRED") {
        this._forcePasswordRecovery();
        return;
      }
      await this.loadUserDetails(email);
    }
  }
  async checkAndRegisterSSOUser(email: string) {
    this.userService.checkAndRegisterSSOUser(email).subscribe({
      next: (res) => {
        this.loadUserDetails(email);
      },
      error: (err) => {
        this.logger.error('[get user details failed]: ', err);
        this.loginMessage = null;
        this.isLoading = false;
        this.loginForm.setErrors({ loginError: 'Something went wrong' });
      },
    });
  }

  async loadUserDetails(email) {
    this.loginMessage = `Retrieving User Details..`;
    this.userService.getUserDetails().subscribe({
      next: (res) => {
        this.isLoading = false;
        if (res == null) {
          this.loginForm.setErrors({ loginError: 'Something went wrong' });
          this.loginMessage = null;
        } else {
          this.loginMessage = `Launching Application..`;
          this.store.dispatch(new AddUserDetails({ ...res, email }));
          this.router.navigate(['prism', 'dashboard']).then();
        }
      },
      error: (err) => {
        let errorMessage = 'Something went wrong';
        if(err?.message?.toLowerCase().includes('account is locked'))
          errorMessage = err?.message;
        this.logger.error('[get user details failed]: ', err);
        this.loginMessage = null;
        this.isLoading = false;
        this.loginForm.setErrors({ loginError: errorMessage });
      },
    });
  }

  navigateToAccountRecovery() {
    this.router.navigate(['account-recovery']);
  }

  private _forcePasswordRecovery() {
    this.toastService.toast(`Password expired, please reset your password to continue`, TOAST_TYPE.WARN);
    this.navigateToAccountRecovery();
  }

  private async _checkInitialTempPasswordLogin() {
    const { email } = this.loginForm.getRawValue();
    try {
      let AuthResponse = await Auth.signIn(email, environment.TEMP_PASSWORD);
      if(AuthResponse.challengeName == "NEW_PASSWORD_REQUIRED") {
        this._forcePasswordRecovery();
        return true;
      } else
        return false;
    } catch (error: any) {
      return false;
    }
  }
}
