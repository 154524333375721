import {
  bootstrapArrowBarRight,
  bootstrapBootstrapReboot,
  bootstrapCheck2,
  bootstrapChevronDown,
  bootstrapFolder,
  bootstrapFunnel,
  bootstrapFunnelFill,
  bootstrapStack,
  bootstrapTrash3,
  bootstrapHouse,
  bootstrapFileEarmarkBarGraph,
  bootstrapCollection,
  bootstrapGear,
  bootstrapPeople,
  bootstrapChevronRight,
  bootstrapChevronLeft,
  bootstrapJustify,
  bootstrapDownload,
  bootstrapArrowLeftCircle,
  bootstrapPlusCircle,
  bootstrapBuildings,
  bootstrapXCircle,
  bootstrapVectorPen,
  bootstrapCopy,
  bootstrapCheck,
  bootstrapInfoCircle,
  bootstrapLightbulb,
  bootstrapFan,
  bootstrapCloud,
  bootstrapEye,
  bootstrapEyeSlash,
  bootstrapQuestion,
  bootstrapPersonFillAdd,
  bootstrapMailbox,
  bootstrapFileEarmarkPlusFill
} from '@ng-icons/bootstrap-icons';

export const Icons = {
  bootstrapArrowBarRight,
  bootstrapStack,
  bootstrapChevronDown,
  bootstrapCheck2,
  bootstrapFolder,
  bootstrapBootstrapReboot,
  bootstrapFunnel,
  bootstrapFunnelFill,
  bootstrapTrash3,
  bootstrapHouse,
  bootstrapFileEarmarkBarGraph,
  bootstrapCollection,
  bootstrapGear,
  bootstrapPeople,
  bootstrapChevronRight,
  bootstrapChevronLeft,
  bootstrapJustify,
  bootstrapDownload,
  bootstrapArrowLeftCircle,
  bootstrapPlusCircle,
  bootstrapBuildings,
  bootstrapXCircle,
  bootstrapVectorPen,
  bootstrapCopy,
  bootstrapCheck,
  bootstrapInfoCircle,
  bootstrapLightbulb,
  bootstrapFan,
  bootstrapCloud,
  bootstrapEye,
  bootstrapEyeSlash,
  bootstrapQuestion,
  bootstrapPersonFillAdd,
  bootstrapMailbox,
  bootstrapFileEarmarkPlusFill
};
