export const TOAST_MESSAGES = {
  ROLE_SWITCH_MESSAGE: (roleName: string) =>
    `You are acting as <br>${roleName} user`,
  OPEN_CREATED_REPORT_SCREEN: `Opening Report...`,
  OPEN_NEW_REPORT_SCREEN: `Preparing screens...`,
  OPEN_TEMPLATE_SCREEN: `Opening Template...`,
  REPORT_TRIGGER_SUCCESS: `Report job triggered Successfully`,
  REPORT_SUBSCRIBE_SUCCESS: `Subscribed successfully`,
  REPORT_UNSUBSCRIBE_SUCCESS: `Un-Subscribed successfully`,
  INVALID_REQUEST: `Invalid Request`,
  INVALID_INVITATION: `Invitation expired / invalid`,
  USER_INVITE_SUCCESS: `User invited`,
  USER_DELETE_SUCCESS: `User deleted successfully`,
};

export const DASHBOARD = {
  CHART: {
    TOTAL_AWS_SAVINGS: {
      LEGENDS: {
        AWS: 'AWS OnDemand Cost',
        PRISM: 'Cost after Discounts (EDP, SP, RI, PRISM etc)',
        PRISM_SAVINGS: 'Discount / Savings',
      },
    },
    PRISM_SAVINGS: {
      GROUP: {
        AWS: 'AWS OnDemand Cost',
        PRISM: 'Cost after PRISM Discounts',
      },
    },
    EDP_COMMITMENT: {
      LEGENDS: {
        COMMIT: 'Contract Usage Commitment',
        ACTUAL: 'Actual Usage Cost',
        MARKETPLACE: 'Marketplace Usage Cost',
      },
    },
  },
};

export enum INFO_MESSAGES {
  NO_EDP_DATA_FOUND = 'NO_EDP_DATA_FOUND',
}

export const AWS_REGIONS = [
  { title: "ap-northeast-1 (Tokyo)", region: "ap-northeast-1" },
  { title: "ap-northeast-2 (Seoul)", region: "ap-northeast-2" },
  { title: "ap-northeast-3 (Osaka)", region: "ap-northeast-3" },
  { title: "ap-south-1 (Mumbai)", region: "ap-south-1" },
  { title: "ap-southeast-1 (Singapore)", region: "ap-southeast-1" },
  { title: "ap-southeast-2 (Sydney)", region: "ap-southeast-2" },
  { title: "ca-central-1 (Central Canada)", region: "ca-central-1" },
  { title: "eu-central-1 (Frankfurt)", region: "eu-central-1" },
  { title: "eu-north-1 (Stockholm)", region: "eu-north-1" },
  { title: "eu-west-1 (Ireland)", region: "eu-west-1" },
  { title: "eu-west-2 (London)", region: "eu-west-2" },
  { title: "eu-west-3 (Paris)", region: "eu-west-3" },
  { title: "me-south-1 (Bahrain)", region: "me-south-1" },
  { title: "sa-east-1 (São Paulo)", region: "sa-east-1" },
  { title: "us-east-1 (N. Virginia)", region: "us-east-1" },
  { title: "us-east-2 (Ohio)", region: "us-east-2" },
  { title: "us-west-1 (N. California)", region: "us-west-1" },
  { title: "us-west-2 (Oregon)", region: "us-west-2" },
]