import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import humps from 'humps';
import {catchError, map, Observable, of} from "rxjs";
import {
  IAWSAccount,
  IInviteUserObject,
  IOrganizationAccount
} from "../../interface/user.interface";
import { keysToCamelCase } from '../../utils/common.util';

@Injectable({
  providedIn: 'root'
})
export class SetupService {

  constructor(private httpClient: HttpClient) {
  }

  getAccountUsers(page: number, limit: number, orgId: string): Observable<{data: IInviteUserObject[], totalDataCount: number, totalPageCount: number}> {
    const url = `${environment.SERVICE_BASE_URL}/get-all-invited-users?page=${page}&limit=${limit}&orgId=${orgId}`
    return this.httpClient.get<{data: IInviteUserObject[], totalDataCount: number, totalPageCount: number}>(url).pipe(map((res) => (keysToCamelCase(res) as {
      data: IInviteUserObject[],
      totalDataCount: number,
      totalPageCount: number
    })),
      catchError(err => of({
        data: [] as IInviteUserObject[],
        totalDataCount: 0 as number,
        totalPageCount: 0 as number
    })))
  }

  searchAccountUsers(searchKey: string, page: number, limit: number): Observable<{data: IInviteUserObject[], totalDataCount: number, totalPageCount: number}> {
    const url = `${environment.SERVICE_BASE_URL}/search-all-invited-users?searchKey=${searchKey}&page=${page}&limit=${limit}`
    return this.httpClient.get<{data: IInviteUserObject[], totalDataCount: number, totalPageCount: number}>(url).pipe(map((res) => (keysToCamelCase(res) as {
      data: IInviteUserObject[],
      totalDataCount: number,
      totalPageCount: number
    })),
      catchError(err => of({
        data: [] as IInviteUserObject[],
        totalDataCount: 0 as number,
        totalPageCount: 0 as number
    })))
  }

  deleteUser(email: string) {
    const url = `${environment.SERVICE_BASE_URL}/delete-user?email=${email}`
    return this.httpClient.delete(url);
  }

  getAllAWSAccounts(page: number, limit: number, orgId: string): Observable<{data: IAWSAccount[], totalDataCount: number, totalPageCount: number}> {
    const url = `${environment.SERVICE_BASE_URL}/get-all-mpa-accounts?page=${page}&limit=${limit}&orgId=${orgId}`;
    return this.httpClient.get<{data: IAWSAccount[], totalDataCount: number, totalPageCount: number}>(url).pipe(
      map((res) => (keysToCamelCase(res) as {
          data: IAWSAccount[],
          totalDataCount: number,
          totalPageCount: number
      })),
      catchError(err => of({
        data : [] as IAWSAccount[],
        totalDataCount: 0 as number,
        totalPageCount: 0 as number
      })));
  }

  getAWSAccountSearch(searchKey: string, page: number, limit: number, orgId: string): Observable<{data: IAWSAccount[], totalDataCount: number, totalPageCount: number}> {
    let url = `${environment.SERVICE_BASE_URL}/search-all-mpa-accounts?searchKey=${searchKey}&page=${page}&limit=${limit}&orgId=${orgId}`;
    return this.httpClient.get<{data: IAWSAccount[], totalDataCount: number, totalPageCount: number}>(url).pipe(
      map((res) => keysToCamelCase(res) as {
          data: IAWSAccount[],
          totalDataCount: number,
          totalPageCount: number
      }),
      catchError(err => of({
        data : [] as IAWSAccount[],
        totalDataCount: 0 as number,
        totalPageCount: 0 as number
      })));
  }

  getAccountSearch(searchKey: string, underOrganizationId?: string): Observable<IAWSAccount[]> {
    let url = `${environment.SERVICE_BASE_URL}/search-mpa-accounts?type=account&searchKey=${searchKey}`;
    if(underOrganizationId) url += `&organizationId=${underOrganizationId}`;
    return this.httpClient.get<IAWSAccount[]>(url).pipe(
      map((res) => keysToCamelCase(res) as IAWSAccount[]),
      catchError(err => of([] as IAWSAccount[])));
  }

  getOrganizationSearch(searchKey: string): Observable<IAWSAccount[]> {
    const url = `${environment.SERVICE_BASE_URL}/search-mpa-accounts?type=organization&searchKey=${searchKey}`;
    return this.httpClient.get<IAWSAccount[]>(url).pipe(
      map((res) => keysToCamelCase(res) as IAWSAccount[]),
      catchError(err => of([] as IAWSAccount[])));
  }

  inviteUsers(data: IInviteUserObject) {
    const url = `${environment.SERVICE_BASE_URL}/invite-user`;
    return this.httpClient.post(url, humps.decamelizeKeys(data));
  }

  getOrganizationAccounts(orgId: string): Observable<IOrganizationAccount[]> {
    const url = `${environment.SERVICE_BASE_URL}/get-organization-accounts?orgId=${orgId}`;
    return this.httpClient.get(url).pipe(
      map(res => keysToCamelCase(res) as IOrganizationAccount[]),
      catchError((err) => {
        console.error(err);
        return of([] as IOrganizationAccount[])
      })
    )
  }

  getInviteDetailsByEmail(email: string) {
    const url = `${environment.SERVICE_BASE_URL}/get-invite-detail-by-email?email=${email}`;
    return this.httpClient.get(url).pipe(
      map(res => keysToCamelCase(res) as IInviteUserObject),
      catchError((err) => {
        console.error(err);
        return of({} as IInviteUserObject)
      })
    )
  }

  getInvitedUserDetails() {
    const url = `${environment.SERVICE_BASE_URL}/get-invite-user-detail`;
    return this.httpClient.get<{email: string, org_name: string}>(url);
  }

  createUser(payload: { email: string, displayName: string, password: string }) {
    const url = `${environment.SERVICE_BASE_URL}/create-user`;
    return this.httpClient.post(url, humps.decamelizeKeys(payload));
  }

  modifyUser(data: IInviteUserObject) {
    const url = `${environment.SERVICE_BASE_URL}/modify-user`;
    return this.httpClient.post(url, humps.decamelizeKeys(data));
  }

  updateUserLock(userId: string, is_locked: boolean) {
    const url = `${environment.SERVICE_BASE_URL}/update-user-lock?userId=${userId}&lock=${is_locked}`;
    return this.httpClient.post(url, {});
  }

}
