<div class="playground-container">
    <div class="layout-json">
    </div>
    <div class="grid-container">
        <ktd-grid [cols]="cols"
                  [rowHeight]="rowHeight"
                  [layout]="layout"
                  [compactType]="compactType"
                  [preventCollision]="preventCollision"
                  [scrollableParent]="autoScroll ? document : null"
                  scrollSpeed="4"
                  (dragStarted)="onDragStarted($event)"
                  (resizeStarted)="onResizeStarted($event)"
                  (dragEnded)="onDragEnded($event)"
                  (resizeEnded)="onResizeEnded($event)"
                  (layoutUpdated)="onLayoutUpdated($event)">
            <ktd-grid-item *ngFor="let item of layout; trackBy:trackById"
                [id]="item.id"
                [transition]="currentTransition"
                [dragStartThreshold]="dragStartThreshold"
                [draggable]="!disableDrag"
                [resizable]="!disableResize"
                class="relative group border border-gray-200 hover:border-gray-300 hover:shadow-lg rounded-md">
                    <div class="p-4 rounded-md">
                        <div class="grid-item-content overflow-scroll">
                            <div [id]="'widget-'+item.id"></div>
                        </div>
                    </div>
                    <div class="absolute top-0 right-0 p-2 flex group-hover:bg-white group-hover:rounded-md">
                        <div class="grid-item-remove-handle mx-2 cursor-pointer opacity-0 group-hover:opacity-100 group-hover:text-gray-500"
                            (mousedown)="stopEventPropagation($event)"
                            (click)="configureWidgetEvent.emit(item.id)">
                            <ng-icon name="bootstrapGear" />
                        </div>
                        <div class="grid-item-remove-handle  mx-2 cursor-pointer opacity-0 group-hover:opacity-100 group-hover:text-gray-500"
                            *ngIf="!disableRemove"
                            (mousedown)="stopEventPropagation($event)"
                            (click)="removeItem(item.id)">
                            <ng-icon name="bootstrapTrash3" />
                        </div>
                    </div>
            </ktd-grid-item>
        </ktd-grid>
    </div>
</div>

<!-- 
    Source Package: https://github.com/katoid/angular-grid-layout?tab=readme-ov-file
    Usage Example: 
    ---------------------------------------------
    <prism-dynamic-dashboard [layout]="layout" 
        [addComponent]="addComponent" 
        [removeComponent]="removeComponent"
        (configureWidgetEvent)="EVENT_HANDLER($event)">
            <div #widget id="0">Component 1</div>
            <div #widget id="1">Component 2</div>
            .
            .
            .
            .
            <div #widget id="n">Component n</div>
    </prism-dynamic-dashboard> 


    -----------------------------------------------
    layout: KTX grid  
        eg: layout = [
            {id: '0', x: 5, y: 0, w: 2, h: 3},
            {id: '1', x: 2, y: 2, w: 1, h: 2},
            {id: '2', x: 3, y: 7, w: 1, h: 2},
        ];

    addComponent=Event emitter => to be emitted from parent with id of the widget
        eg: addComponent.emit(1) //Where 1 is the id of the widget component

    removeComponent=Event emitter => to be subscribed in parent component
        eg: removeComponent.subscribe(id => { HANDLE widget removal using id of component })>

    EVENT_HANDLER = (event: id) => { HANDLE configure event here }
        eg: EVENT_HANDLER(id: string) { navigate to configuration seciton of widget id }
    -->