import { Component, ElementRef, HostListener } from '@angular/core';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'prism-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  data: any;

  constructor(
    private modalService: ModalService,
    private element: ElementRef,
  ) {}

  @HostListener('document:keydown.escape')
  onEscape() {
    this.modalService.close();
  }

  onClose() {
    this.modalService.close();
  }

  close() {
    this.element.nativeElement.remove();
    return;
  }
}
