import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../../services/toast/toast.service';
import { BUTTON_STYLES } from '../../../constants/core-components/prism-button.style.constants';
import { SetupService } from '../../../services/setup/setup.service';
import { Subscription, take } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TOAST_MESSAGES } from '../../../constants/common.constant';
import { TOAST_TYPE } from '../../../enums/toastType.enum';
import { Auth } from 'aws-amplify';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'prism-account-recovery',
  templateUrl: './account-recovery.component.html',
  styleUrls: ['./account-recovery.component.scss']
})
export class AccountRecoveryComponent implements OnInit {
  protected readonly BUTTON_STYLES = BUTTON_STYLES;

  loaderMessage: string | null = null;
  resetPasswordForm!: FormGroup<{
    email: FormControl<string>,
    verificationCode: FormControl<string>,
    newPassword: FormControl<string>,
    confirmPassword: FormControl<string>
  }>;
  verificationCodeTime: number = 0;
  subscriptions: Subscription[] = [];
  
  forcedInitialPasswordReset: { isForcedResetPassword: boolean, user: any } = {
    isForcedResetPassword:  false,
    user: null
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private setupService: SetupService,
    private logger: NGXLogger,
    private toastService: ToastService,
    private userService: UserService,
  ) {
    this._createPasswordResetForm();
  }

  ngOnInit() {
    this.verificationCodeTime = 0;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }

  _createPasswordResetForm() {
    this.resetPasswordForm = new FormGroup(
      {
        email: new FormControl<string>('', [Validators.required]),
        newPassword: new FormControl('', [
          Validators.required,
          this.passwordStrengthValidator,
        ]),
        confirmPassword: new FormControl<string>('', [Validators.required]),
        verificationCode: new FormControl<string>(''),
      },
      {
        validators: [this.passwordMatcher as ValidatorFn],
      },
    );
  }

  passwordStrengthValidator(form: FormControl): ValidationErrors | null {
    const password = form?.value || '';
    const hasUpperCase = /[A-Z]+/.test(password);
    const hasLowerCase = /[a-z]+/.test(password);
    const hasNumeric = /[0-9]+/.test(password);
    const hasSpecialCharacters = /[\^\$\*\.\[\]\{\}\(\)\?\-\!"@\#\%\&\/\\,\>\<\'\:\;\|\_\~\`\+\=]/.test(password);
    const hasEightCharacters = password.length >= 8;
    if (hasLowerCase && hasUpperCase && hasNumeric && hasEightCharacters && hasSpecialCharacters)
      return null;
    else return { hasUpperCase, hasLowerCase, hasNumeric, hasSpecialCharacters, hasEightCharacters };
  }

  passwordMatcher(form: FormGroup): ValidationErrors | null {
    const password = form.get('newPassword')?.value;
    const confirmPassword = form.get('confirmPassword')?.value;
    if (password !== confirmPassword) {
      return { passwordMismatch: true };
    }
    return null;
  }

  async getVerificationCode() {
    try {
      let email = this.resetPasswordForm.value.email;
      await Auth.forgotPassword(email);
      this.toastService.toast(`Verification code to sent registered email`)
      this.verificationCodeTime = 30;
      let verificationCodeInterval = setInterval(() => { 
        if(this.verificationCodeTime == 0) 
          clearInterval(verificationCodeInterval); 
        else this.verificationCodeTime--; 
      }, 1000);
    } catch (error: any) {
      if(error.code.includes('UserNotFoundException'))
        this.toastService.toast(`User not found`, TOAST_TYPE.ERROR);
      else if(error.code.includes('NotAuthorizedException') 
        && error.message.includes('User password cannot be reset in the current state.')) {
        this._forcePasswordResetHandlerOnInitialLogin();
      } else if(error.code.includes('LimitExceededException')) {
        this.toastService.toast(`Attempt limit exceeded, please again after sometime`, TOAST_TYPE.WARN, 5000);
      }
    }
  }

  async resetPassword() {
    try {
      this.loaderMessage = `Validating User email address`;
      let userNameListener = this.userService
        .getUsernameInCognitoUsingEmail(this.resetPasswordForm.value.email).subscribe(async (userName) => {
          if(!userName) {
            this.toastService.toast(`User not found`, TOAST_TYPE.ERROR);
            this.loaderMessage = ``;
          } else {
            this.loaderMessage = `Updating password`;
            if(this.forcedInitialPasswordReset.isForcedResetPassword && this.forcedInitialPasswordReset.user) {
              this._handleInitialPasswordReset();
              return;
            }
            await Auth.forgotPasswordSubmit(
              userName, 
              this.resetPasswordForm.value.verificationCode, 
              this.resetPasswordForm.value.newPassword
            )
            .then(resp => {
              this.loaderMessage = ``;
              this.toastService.toast(`Password reset successful, redirecting to login page`, TOAST_TYPE.INFO);
              setTimeout(() => { this.router.navigate(['/']) }, 2000);
            })
            .catch(error => { this.toastService.toast(`${error.message}`, TOAST_TYPE.ERROR) } )
            .finally(() => { this.loaderMessage = ``; })
          }
      })
      this.subscriptions.push(userNameListener);
    } catch (error: any) {
      this.loaderMessage = ``;
      this.toastService.toast(`Error occured on changing password: ${error}`, TOAST_TYPE.ERROR);
      console.error(`Error occured on changing password`);
    }
  }

  private _forcePasswordResetHandlerOnInitialLogin() {
    try {
      Auth.signIn(this.resetPasswordForm.value.email, environment.TEMP_PASSWORD)
        .then(user => {
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
              this.forcedInitialPasswordReset.isForcedResetPassword = true;
              this.forcedInitialPasswordReset.user = user;
              this.resetPasswordForm.get('verificationCode')?.patchValue('123456');
            }
        });
    } catch (error: any) {
      this.toastService.toast(error, TOAST_TYPE.ERROR);
      this.loaderMessage = ``;
    }
  }

  private _handleInitialPasswordReset() {
    let newPassword = this.resetPasswordForm.value.newPassword;
    try {
      Auth.completeNewPassword(this.forcedInitialPasswordReset.user, newPassword)
        .then(user => {
          this.toastService.toast(`Password updated successfully, Login to continue.`, TOAST_TYPE.SUCCESS, 5000);
          this.loaderMessage = `Password Updated`;
          setTimeout(() => { this.router.navigate(['/']) }, 2000);
          this.loaderMessage = ``;
        });
    } catch (error: any) {
      this.toastService.toast(error, TOAST_TYPE.ERROR);
      this.loaderMessage = ``;
    }
  }
}
