import {Pipe, PipeTransform} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({
  name: 'search',
})
export class SearchPipe implements PipeTransform {

  constructor() { }

  transform(items: any, searchText: string, type: string, keyToSearch: string[] = []): any[] {
    let filteredItems: any = [];
    if (!items) {
        return [];
    } else if (!searchText) {
        return items;
    } else if(type.toLowerCase().trim() == 'flex-report-options') {
        return (items as FormGroup[]).filter((item: FormGroup) => {
            return (item.value[keyToSearch[0]] || '').trim().toLowerCase().includes(searchText.toLowerCase().trim());
        });
    } else if(type.toLowerCase().trim() == 'table') {
      return items.filter((item: any) => {
        return keyToSearch.some((key: string) => {
          return Object.keys(item).includes(key) && item[key].value.trim().toLowerCase().includes(searchText.toLowerCase().trim());
        });
      });
    } else {
        searchText = searchText.toLocaleLowerCase();
        filteredItems = items.filter((it: any) => {
          return it[keyToSearch[0]].toLowerCase().includes(searchText);
        });
        return filteredItems;
    }
  }

}

