import { FEATURE_FLAG } from "../../enums/featureFlag.enum";
import {IAccount, IUser} from "../../interface/user.interface";

export class AddUserDetails {
    static readonly type = '[User] Add user details';

    constructor(public payload: IUser) {
    }
}

export class SetSelectedUserRole {
    static readonly type = '[User] Set role';

    constructor(public payload: string) {
    }
}

export class SetSelectedSwitchedUserRole{
    static readonly type = '[User] Set switched role';

    constructor(public payload: string) {
    }
}

export class SetSelectedAccount {
    static readonly type = '[User] Set account';

    constructor(public payload: string) {
    }
}

export class SetSelectedOrganization {
    static readonly type = '[User] Set Organization';

    constructor(public payload: {orgId: string, accounts: IAccount[], featureFlags: FEATURE_FLAG[]}) {
    }
}

export class ResetUserStore {
    static readonly type = '[User] Reset';
}

export class SetUserDetails {
    static readonly type = '[User] User details';

    constructor(public payload: {name: string, phone: string}) {
    }
}