import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../../services/toast/toast.service';
import { BUTTON_STYLES } from '../../../constants/core-components/prism-button.style.constants';
import { SetupService } from '../../../services/setup/setup.service';
import { take } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TOAST_MESSAGES } from '../../../constants/common.constant';
import { TOAST_TYPE } from '../../../enums/toastType.enum';

@Component({
  selector: 'prism-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent implements OnInit, OnDestroy {
  protected readonly BUTTON_STYLES = BUTTON_STYLES;

  loaderMessage: string | null = null;
  inviteUserForm!: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private setupService: SetupService,
    private logger: NGXLogger,
    private toastService: ToastService,
  ) {
    this._createInviteUserForm();
  }

  ngOnInit() {
    const invitedUserTokenId = this.route.snapshot.paramMap.get(
      'invited-user-token-id',
    );
    if (invitedUserTokenId) {
      localStorage.setItem('temp-accessToken', invitedUserTokenId);
      this._fetchUserInfo();
    } else {
      this.toastService.toast(TOAST_MESSAGES.INVALID_REQUEST, TOAST_TYPE.WARN);
      this.router.navigate(['/']).then();
    }
  }

  _createInviteUserForm() {
    this.inviteUserForm = new FormGroup(
      {
        displayName: new FormControl<string>('', [Validators.required]),
        email: new FormControl<string>('', [Validators.required]),
        password: new FormControl<string>('', [
          Validators.required,
          this.passwordStrengthValidator,
        ]),
        orgName: new FormControl<string>(''),
        confirmPassword: new FormControl<string>('', [Validators.required]),
      },
      {
        validators: [this.passwordMatcher as ValidatorFn],
      },
    );
  }

  passwordStrengthValidator(form: FormControl): ValidationErrors | null {
    const password = form?.value || '';
    const hasUpperCase = /[A-Z]+/.test(password);
    const hasLowerCase = /[a-z]+/.test(password);
    const hasNumeric = /[0-9]+/.test(password);
    const hasSpecialCharacters = /[\^\$\*\.\[\]\{\}\(\)\?\-\!"@\#\%\&\/\\,\>\<\'\:\;\|\_\~\`\+\=]/.test(password);
    const hasEightCharacters = password.length >= 8;
    if (hasLowerCase && hasUpperCase && hasNumeric && hasEightCharacters && hasSpecialCharacters)
      return null;
    else return { hasUpperCase, hasLowerCase, hasNumeric, hasSpecialCharacters, hasEightCharacters };
  }

  passwordMatcher(form: FormGroup): ValidationErrors | null {
    const password = form.get('password')?.value;
    const confirmPassword = form.get('confirmPassword')?.value;
    if (password !== confirmPassword) {
      return { passwordMismatch: true };
    }
    return null;
  }

  patchForm(formData: { email: string; org_name: string }) {
    this.inviteUserForm.get('email')?.patchValue(formData.email);
    this.inviteUserForm.get('orgName')?.patchValue(formData.org_name || '');
  }

  _fetchUserInfo() {
    this.loaderMessage = 'Loading Invitation';
    this.setupService
      .getInvitedUserDetails()
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.loaderMessage = '';
          this.patchForm(res);
        },
        error: (err) => {
          this.toastService.toast(
            TOAST_MESSAGES.INVALID_INVITATION,
            TOAST_TYPE.WARN,
            5000,
          );
          this.router.navigate(['/']);
        },
      });
  }

  createUser() {
    this.inviteUserForm.markAllAsTouched();
    if (this.inviteUserForm.valid) {
      this.loaderMessage = 'Creating User';
      this.setupService
        .createUser(this.inviteUserForm.getRawValue())
        .subscribe({
          next: (res) => {
            this.loaderMessage = '';
            this.toastService.toast(
              'Account updated, Please login to access the application',
              TOAST_TYPE.SUCCESS,
              5000,
            );
            this.router.navigate(['/']);
          },
          error: (err) => {
            this.loaderMessage = '';
            this.toastService.toast(err, TOAST_TYPE.ERROR, 10000);
          },
        });
    }
  }

  ngOnDestroy() {
    localStorage.removeItem('temp-accessToken');
  }
}
