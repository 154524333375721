import { ChangeDetectorRef, Component, ContentChildren, ElementRef, EventEmitter, Inject, Input, NgZone, OnDestroy, OnInit, Output, QueryList, ViewChild } from '@angular/core';
import { fromEvent, merge, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import {
    KtdDragEnd, KtdDragStart, KtdGridComponent, KtdGridLayout, KtdGridLayoutItem, KtdResizeEnd, KtdResizeStart, ktdTrackById
} from '@katoid/angular-grid-layout';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'prism-dynamic-dashboard',
  templateUrl: './dynamic-dashboard.component.html',
  styleUrls: ['./dynamic-dashboard.component.scss']
})
export class DynamicDashboardComponent {
  @ViewChild(KtdGridComponent, {static: true}) grid: KtdGridComponent;
  trackById = ktdTrackById;

  @ContentChildren('widget') contentElements: QueryList<ElementRef> = new QueryList<null>();
  contentArray: any[] = [];

  @Input() removeComponent = new EventEmitter<string>();
  @Input() addComponent = new EventEmitter<string>();
  @Input() renderContent = new EventEmitter<string>();
  @Input() layout: KtdGridLayout = 
  [
      {id: '0', x: 5, y: 0, w: 2, h: 3},
      {id: '1', x: 2, y: 2, w: 1, h: 2},
      {id: '2', x: 3, y: 7, w: 1, h: 2},
  ];
  @Output() configureWidgetEvent = new EventEmitter<string>();
  @Output() widgetResized = new EventEmitter<string>();

  cols = 12;
  rowHeight = 50;
  compactType: 'vertical' | 'horizontal' | null = 'vertical';
  currentTransition: string = 'transform 500ms ease, width 500ms ease, height 500ms ease';
  dragStartThreshold = 0;
  autoScroll = true;
  disableDrag = false;
  disableResize = false;
  disableRemove = false;
  autoResize = true;
  preventCollision = false;
  isDragging = false;
  isResizing = false;
  resizeSubscription: Subscription;
  subcriptions: Subscription[] = []

  constructor(
    private cdRef: ChangeDetectorRef,
    private ngZone: NgZone, 
    public elementRef: ElementRef, 
    @Inject(DOCUMENT) public document: Document
  ) {
      
  }

  ngOnInit() {
    this.resizeSubscription = merge(
          fromEvent(window, 'resize'),
          fromEvent(window, 'orientationchange')
      ).pipe(
          debounceTime(50),
          filter(() => this.autoResize)
      ).subscribe(() => {
          this.grid.resize();
      });
    this.subcriptions.push(this.resizeSubscription);
    let addCompSubscription = this.addComponent.subscribe(id => {
      this.addItemToLayout(id)
    });
    this.subcriptions.push(addCompSubscription);
    let render = this.renderContent.subscribe(id => this.updateContentArray(id));
    this.subcriptions.push(render);
  }

  ngOnDestroy() {
      this.subcriptions.forEach(subs => subs.unsubscribe());
  }

  ngAfterContentInit() {
    !this.renderContent && this.updateContentArray();
  }

  ngAfterContentChecked() {
    !this.renderContent && this.updateContentArray();
  }

  onDragStarted(event: KtdDragStart) {
      this.isDragging = true;
  }

  onResizeStarted(event: KtdResizeStart) {
      this.isResizing = true;
  }

  onDragEnded(event: KtdDragEnd) {
      this.isDragging = false;
  }

  onResizeEnded(event: KtdResizeEnd) {
      this.widgetResized.next(event.layoutItem.id);
      this.isResizing = false;
  }

  onLayoutUpdated(layout: KtdGridLayout) {
      this.layout = layout;
  }

  onAutoScrollChange(checked: boolean) {
      this.autoScroll = checked;
  }

  onDisableDragChange(checked: boolean) {
      this.disableDrag = checked;
  }

  onDisableResizeChange(checked: boolean) {
      this.disableResize = checked;
  }

  onDisableRemoveChange(checked: boolean) {
      this.disableRemove = checked;
  }

  onAutoResizeChange(checked: boolean) {
      this.autoResize = checked;
  }

  onPreventCollisionChange(checked: boolean) {
      this.preventCollision = checked;
  }

  onColsChange(event: Event) {
      this.cols = this.coerceNumberProperty((event.target as HTMLInputElement).value);
  }

  onRowHeightChange(event: Event) {
      this.rowHeight = this.coerceNumberProperty((event.target as HTMLInputElement).value);
  }

  onDragStartThresholdChange(event: Event) {
      this.dragStartThreshold = this.coerceNumberProperty((event.target as HTMLInputElement).value);
  }

  generateLayout() {
      const layout: KtdGridLayout = [];
      for (let i = 0; i < this.contentArray.length ||  this.cols; i++) {
          const y = Math.ceil(Math.random() * 4) + 1;
          layout.push({
              x: Math.round(Math.random() * (Math.floor((this.cols / 2) - 1))) * 2,
              y: Math.floor(i / 6) * y,
              w: 2,
              h: y,
              id: i.toString()
              // static: Math.random() < 0.05
          });
      }
      this.layout = layout;
      return this.layout;
  }

  addItemToLayout(id: string | null = null) {
      const maxId = this.layout.reduce((acc, cur) => Math.max(acc, parseInt(cur.id, 10)), -1);
      const nextId = id || (maxId + 1);

      const newLayoutItem: KtdGridLayoutItem = {
          id: nextId.toString(),
          x: 0,
          y: Math.max(...this.layout?.map(item => item.y)) || 0,
          w: 2,
          h: 2
      };


      this.layout = [
        ...this.layout,
        newLayoutItem,
      ];
      this.updateContentArray();
  }

  /**
   * Fired when a mousedown happens on the remove grid item button.
   * Stops the event from propagating an causing the drag to start.
   * We don't want to drag when mousedown is fired on remove icon button.
   */
  stopEventPropagation(event: Event) {
      event.preventDefault();
      event.stopPropagation();
  }

  /** Removes the item from the layout */
  removeItem(id: string) {
      // Important: Don't mutate the array. Let Angular know that the layout has changed creating a new reference.
      this.layout = this.ktdArrayRemoveItem(this.layout, (item) => item.id === id);
      this.removeComponent.emit(id);
  }

  ktdArrayRemoveItem<T>(array: T[], condition: (item: T) => boolean) {
    const arrayCopy = [...array];
    const index = array.findIndex((item) => condition(item));
    if (index > -1) {
        arrayCopy.splice(index, 1);
    }
    return arrayCopy;
  }

  coerceNumberProperty(value: any) {
    if (isNaN(value)) return 0;
    return Number(value);
  }


  private updateContentArray(id: string = "") {
    this.contentArray = this.contentElements.toArray();
    this.contentArray.forEach((contentElement: ElementRef, index: number) => {
      if(id == "" || +id == index) {
          const widgetId = contentElement?.nativeElement?.id;
          const correspondingDiv = document.getElementById('widget-' + widgetId);
          if (correspondingDiv) {
            correspondingDiv.innerHTML = '';
            correspondingDiv.appendChild(contentElement.nativeElement);
          }
      }
        
    });
    this.cdRef.detectChanges();
  }
}
