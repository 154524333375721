import { TooltipModel } from "chart.js";
import { cloneDeep } from "lodash";
import { Colors } from "../constants/chart-colors.constants";
import { CHART_TYPE, IChartComponentConfiguration } from "../constants/chart-data.constants";
import { IChartData, IChartDataset } from "../interface/chart-data.interface";
import { IJsonArrayDataGrouperConfig, jsonArrayDataGrouper } from "./common.util";

export const yearSeprationLine = {
  id: 'yearSeparationLine',
  beforeDraw(chart: any, args: any, option: any) {
    let beforeText = option.before;
    let afterText = option.after;
    const { ctx, chartArea: { top, right, bottom, left, width, height }, scales: { x, y } } = chart;
    ctx.save();
    ctx.strokeStyle = 'rgba(128, 128, 128, 0.5';
    ctx.lineWidth = 2;
    ctx.setLineDash([5, 5]);
    // let janindex = (x.ticks as Array<{value: number, label: string}>).findIndex(label => ['january', 'jan'].includes(label.label.toString().toLowerCase().trim()))
    let janindex = (x.ticks as Array<{ value: number, label: string }>).findIndex(label => label.label.toString().toLowerCase().trim().split(' ').some(lab => lab.includes('january') || lab.includes('jan')));
    janindex = janindex >= 0 ? janindex : (x.ticks as Array<{ value: number, label: string }>).findIndex(label => label.label.toString().toLowerCase().trim().split(' ').some(lab => lab.includes('december') || lab.includes('december')));
    if (janindex && janindex > 0) {
      ctx.strokeRect(x.getPixelForValue(janindex) - ((x.getPixelForValue(janindex) - x.getPixelForValue(janindex - 1)) / 2), top - 100, 0, height + 150);
      if (beforeText && afterText) {
        ctx.fillStyle = 'grey';
        ctx.fillText(`${beforeText}`, (x.getPixelForValue(janindex) - ((x.getPixelForValue(janindex) - x.getPixelForValue(janindex - 1)) / 2)) - 40, bottom + 40);
        ctx.fillText(`${afterText}`, (x.getPixelForValue(janindex) - ((x.getPixelForValue(janindex) - x.getPixelForValue(janindex - 1)) / 2)) + 15, bottom + 40);
      }
    }
    ctx.restore();
  }
}

export const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart: any, args: any, options: any) {
    const ul = getOrCreateLegendList(chart, options.containerID);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item: any) => {
      const li = document.createElement('li');
      li.style.alignItems = 'center';
      li.style.cursor = 'pointer';
      li.style.display = 'flex';
      li.style.flexDirection = 'row';
      li.style.marginLeft = '10px';

      li.onclick = () => {
        const { type } = chart.config;
        if (type === 'pie' || type === 'doughnut') {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index);
        } else {
          chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
        }
        chart.update();
      };

      // Color box
      const boxSpan = document.createElement('span');
      boxSpan.style.background = item.fillStyle;
      boxSpan.style.borderColor = item.strokeStyle;
      boxSpan.style.borderWidth = item.lineWidth + 'px';
      boxSpan.style.display = 'inline-block';
      boxSpan.style.flexShrink = '0';
      boxSpan.style.height = '20px';
      boxSpan.style.marginRight = '10px';
      boxSpan.style.width = '20px';

      // Text
      const textContainer = document.createElement('p');
      textContainer.style.color = item.fontColor;
      textContainer.style.margin = '0';
      textContainer.style.padding = '0';
      textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  }
};

export const getOrCreateLegendList = (chart: any, id: string) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer?.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.style.display = 'flex';
    listContainer.style.flexDirection = 'row';
    (listContainer as HTMLElement).style.margin = '0';
    (listContainer as HTMLElement).style.padding = '0';

    legendContainer?.appendChild(listContainer);
  }

  return listContainer
};

export const amountTransform = (value: number): string => {
  if (value >= 1000000) {
    return `$${(value / 1000000).toFixed(2)}M`
  } else if (value > 1000) {
    return `$${(value / 1000).toFixed(1)}K`
  } else {
    return `$${value.toFixed(2)}`
  }
}

export const convertJSONtoPieChart = (jsonData: { [key: string]: string | number }[], config: IJsonPieChartConfiguration): IChartData => {
  //Group JSON data according to selected column for visualization
  let groupOptions: IJsonArrayDataGrouperConfig = {
    columns: [
      { columnName: config.labelColumnName },
      { columnName: config.numericColumnName, groupingOperation: config.numericGroupingOpeartion },
    ]
  }
  let groupedJson = jsonArrayDataGrouper(jsonData, groupOptions);
  groupedJson.sort((a, b) => a[config.labelColumnName].toUpperCase() < b[config.labelColumnName].toUpperCase() ? -1 : 1);
  //Prepare Chart data using grouped JSON
  let chartDataSet: IChartData = {
    labels: groupedJson.map(rec => rec[config.labelColumnName]),
    datasets: [{
      label: '',
      data: groupedJson.map(rec => rec[config.numericColumnName]).sort((a, b) => a-b),
      backgroundColor: groupedJson.map((key: string, index: number) => Colors[index]),
      barThickness: 27
    }]
  };
  return chartDataSet;
}

export const convertJSONtoBarChart = (jsonData: { [key: string]: string | number }[], config: IJsonBarChartConfiguration): IChartData => {
  //Group JSON data according to selected column for visualization
  let groupOptions: IJsonArrayDataGrouperConfig = {
    columns: [
      { columnName: config.XAxisStackColumnName },
      { columnName: config.xAxisGroupingColumnName },
      { columnName: config.yAxisColumnName, groupingOperation: config.numericGroupingOpeartion },
    ]
  }
  let groupedJson = jsonArrayDataGrouper(jsonData, groupOptions);
  //Prepare Chart data using grouped JSON
  let chartDataSet: IChartData = { labels: [], datasets: [] }
  chartDataSet.labels = [...new Set(
    groupedJson.map(record => record[config.xAxisGroupingColumnName])
  )];
  chartDataSet.labels.sort();
  [...new Set(
    groupedJson.map(record => record[config.XAxisStackColumnName])
  )]?.sort()?.forEach((stack, index) => {

    chartDataSet.datasets.push({
      label: stack,
      data: [],
      backgroundColor: Colors[index + 1],
      borderColor: Colors[index + 1],
      fill: config.isAreaChart ? 'origin': '',
      barThickness: 27
    })
  });
  chartDataSet.datasets?.forEach((stack: { label: string, data: number[], backgroundColor: string, barThickness: number }) => {
    chartDataSet.labels.forEach((xAxisLabel: string | number) => {
      let data = groupedJson.filter(record => {
        return record[config.XAxisStackColumnName]?.toString() == stack.label
          && record[config.xAxisGroupingColumnName]?.toString() == xAxisLabel
      })?.reduce((sum, obj) => sum + (+obj[config.yAxisColumnName] || 0), 0);
      stack?.data?.push(data);
    })
    if(config.customBarThickness) {
      stack.barThickness = (300 / (config.isStacked ? 1 : (chartDataSet.datasets.length)*(stack.data.length || 1)));
      stack.barThickness = stack.barThickness < 27 ? 27 : stack.barThickness;
      stack.barThickness = stack.barThickness > 80 ? 80 : stack.barThickness;
    }
  })
  return chartDataSet;
}

export const UpdateFilteredChartData = (data: IChartData, configuration: IChartComponentConfiguration): 
  {
    filteredChartData: IChartData, 
    legendValueMapper: { [key in string]: string | number }
  } => {
    const filteredChartData = cloneDeep(data) as IChartData;
    const legendValueMapper = {};
    filteredChartData.datasets = filteredChartData.datasets.map((dataset: IChartDataset) => {
      if(configuration?.isColoredLine) dataset.borderColor = configuration?.isColoredLine ? configuration?.lineColor : dataset.backgroundColor;
      if(configuration?.showArea || dataset.fill) dataset.fill = configuration?.showArea ? dataset.fill : null;
      dataset['hidden'] = false;
      return dataset;
    });
    filteredChartData.labels.forEach((label, index) => {
      legendValueMapper[label] = filteredChartData.datasets[0].data[index];
    });
    return {filteredChartData, legendValueMapper};
}

export const ToggleDatasetVisibility = (data: IChartData, stackName: string, groupName: string | null): IChartData => {
  const updatedDatasets = data.datasets.map((dataset: IChartDataset) => {
    const isSameStack = dataset.label === stackName;
    const isSameGroup = groupName !== null ? dataset.stack === groupName : true;
    if (isSameStack && isSameGroup) {
      return { ...dataset, hidden: !dataset.hidden };
    }
    return dataset;
  });
  return { ...data, datasets: updatedDatasets };
};

export const createToolTipElementInHtml = (tooltipModel: {tooltip: TooltipModel<CHART_TYPE>, chart: any} | null) => {
  // Tooltip Element
  let tooltipEl = document.getElementById('chartjs-tooltip');
  //Remove tooltip elemnt is previously been created
  if(tooltipEl) tooltipEl.remove();
  tooltipEl = document.createElement('div');
  tooltipEl.className = `rounded-xl bg-black text-white p-4 bg-opacity-70 backdrop-blur-md`
  tooltipEl.id = 'chartjs-tooltip';
  tooltipEl.innerHTML = `
    <div id="tooltip-title" class="text-bold text-sm py-1">${tooltipModel.tooltip.title}</div>
    <div id="tooltip-body" class="w-full"></div>
  `;
  document.body.appendChild(tooltipEl);
  return tooltipEl;
}

export const setTooltipCaretPosition = (tooltipEl: HTMLElement, tooltipModel: {tooltip: TooltipModel<CHART_TYPE>, chart: any} | null) => {
  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltipModel.tooltip.yAlign) {
      tooltipEl.classList.add(tooltipModel.tooltip.yAlign);
  } else {
      tooltipEl.classList.add('no-transform');
  }
}

export const getTooltipInnerHtml = (tooltipModel: {tooltip: TooltipModel<CHART_TYPE>, chart: any} | null): string => {
  // let allLegends: any[] = tooltipModel.chart.legend.legendItems;
  // let stackGroupsCount: number = allLegends.reduce((count: number, compValue: {text: string}) => {
  //   return compValue.text === allLegends[0].text ? count + 1 : count;
  // }, 0);
  // let singleStackLength = allLegends.length / stackGroupsCount;
  // let legendStartIndex = (Math.floor(tooltipModel?.tooltip?.dataPoints[0]?.datasetIndex/singleStackLength) * singleStackLength)
  let dataIndex = tooltipModel.chart.tooltip.dataPoints[0].dataIndex;
  let hoveredLabel = tooltipModel.chart.tooltip.dataPoints[0].dataset.label;
  let stackName = tooltipModel.chart.tooltip.dataPoints[0].dataset.stack;
  let stackedData = stackName
    ? (tooltipModel.chart.$context.chart.config._config.data.datasets.filter(ds => ds.stack == stackName))
    : tooltipModel.chart.$context.chart.config._config.data.datasets;
  let requiredDatasets = stackedData.slice();//tooltipModel.chart.$context.chart.config._config.data.datasets.slice(legendStartIndex, legendStartIndex + singleStackLength);
  let innerHtml = `
    <div class="px-1 py-2">${stackName || ''}</div>
    <div class='px-1 flex flex-col w-full'>`
  requiredDatasets.sort((a,b) => a.label?.localeCompare(b.label)).forEach(function(dataset, index) {
      innerHtml += `
        <div class='flex w-full'>
          <div class='flex mr-1 items-center'>
          <div class="w-3 h-3 rounded" style="background: ${dataset.backgroundColor};"></div>
            <div class='w-full px-1 text-white ${hoveredLabel == dataset.label ? ' text-lg ' : 'text-opacity-70'} '>${dataset.label}: &nbsp;${getReadableFormattedCurrency(dataset.data[dataIndex] || 0)}</div>
          </div>
        </div>
      `;
    });
  innerHtml += `</div>`
  return innerHtml
}

export const patchInnerHtmlOfTooltipBodyElement = (tooltipEl: HTMLElement, innerHtml: string) => {
  let divRoot = tooltipEl.querySelector('#tooltip-body');
  if(divRoot) {
    divRoot.setAttribute('style', 'font-size: 12px;');
    divRoot.innerHTML = innerHtml;
  }
}

export const setTooltipPositioning = (tooltipEl: HTMLElement, tooltipModel: {tooltip: TooltipModel<CHART_TYPE>, chart: any} | null) => {
  const position = tooltipModel.chart.canvas.getBoundingClientRect();
  // Display, position, and set styles for font
  tooltipEl.style.opacity = '1';
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.tooltip.caretX + 'px';
  tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.tooltip.caretY + 'px';
  const rect = tooltipEl.getBoundingClientRect();
  if(rect.bottom >= window.innerHeight) {
    tooltipEl.style.top = `${window.innerHeight - rect.height - 10}px`;
  }
  tooltipEl.style.padding =  + '10px ' + '10px';
  tooltipEl.style.pointerEvents = 'none';
}

export const customTooltip = (tooltipModel: {tooltip: TooltipModel<CHART_TYPE>, chart: any} | null) => {
  let tooltipEl = createToolTipElementInHtml(tooltipModel);
  // Hide if no tooltip
  if (tooltipModel.tooltip.opacity === 0) {
      tooltipEl.style.opacity = '0';
      return;
  }
  setTooltipCaretPosition(tooltipEl, tooltipModel);
  if (tooltipModel.tooltip.body) {
     let innerHtml = getTooltipInnerHtml(tooltipModel);
     patchInnerHtmlOfTooltipBodyElement(tooltipEl, innerHtml);
  }
  setTooltipPositioning(tooltipEl, tooltipModel);
}

export const getReadableFormattedCurrency = (value: number): string => {
  const absParsed = Math.abs(value);
  const valueInMillions = absParsed / 1000000;
  const valueInThousands = absParsed / 1000;
  const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
  const formattedValue = formatter.format(valueInMillions < 1 ? (valueInThousands < 1 ? absParsed : valueInThousands) : valueInMillions);
  const suffix = valueInMillions < 1 ? (valueInThousands < 1 ? '' : 'K') : 'M';
  return `${formattedValue}${suffix}`;
}

export const ChartHoverYAxisLine = {
  id: 'ChartHoverYAxisLine',
  beforeDraw(chart) {
    const { ctx, chartArea: { top, right, bottom, left }, scales: { y }, tooltip } = chart;
    const isStacked = chart.options.scales.y.stacked || chart.options.scales.yAxes?.some(axis => axis.stacked);
    if (tooltip._active.length > 0) {
      const activePoint = tooltip._active[0]?.element;
      if (activePoint) {
        const dataIndex = activePoint.$context.dataIndex;
        if (isStacked) {
          const topSegmentIndex = chart.data.datasets.length - 1;
          const topSegmentY = chart.getDatasetMeta(topSegmentIndex).data[dataIndex].y;
          const totalValue = chart.data.datasets.reduce((total, dataset) => {
            return total + (dataset.data[dataIndex] || 0);
          }, 0);
          ctx.beginPath();
          ctx.strokeStyle = 'grey';
          ctx.lineWidth = 1;
          ctx.moveTo(left, topSegmentY);
          ctx.lineTo(right, topSegmentY);
          ctx.stroke();
          const valueToShow = `Total: ${+(totalValue).toFixed(2)}`;
          ctx.fillStyle = 'rgba(211, 211, 211, 0.5)';
          const textWidth = ctx.measureText(valueToShow).width;
          const padding = 5;
          ctx.fillRect(left + 5, topSegmentY - 20, textWidth + padding * 2, 20);
          ctx.fillStyle = 'black';
          ctx.font = '12px Arial';
          ctx.fillText(valueToShow, left + 10, topSegmentY - 5);
          ctx.restore();
        } else {
          ctx.beginPath();
          ctx.strokeStyle = 'grey';
          ctx.lineWidth = 1;
          const yPos = activePoint.y;
          ctx.moveTo(left, yPos);
          ctx.lineTo(right, yPos);
          ctx.stroke();
          const valueToShow = `${+(activePoint.$context.raw).toFixed(2)}`;
          ctx.fillStyle = 'rgba(211, 211, 211, 0.5)';
          const textWidth = ctx.measureText(valueToShow).width;
          const padding = 5;
          ctx.fillRect(left + 5, yPos - 20, textWidth + padding * 2, 20);
          ctx.fillStyle = 'black';
          ctx.font = '12px Arial';
          ctx.fillText(valueToShow, left + 10, yPos - 5);
          ctx.restore();
        }
      }
    }
  }
}

export const reorderPlugin = {
  id: 'reorderPlugin',
  beforeDatasetsDraw(chart) {
    let datasets = chart.data.datasets;
    datasets = datasets
      .sort((a, b) => 
        b.data.reduce((sum, num) => sum + num, 0) 
        - a.data.reduce((sum, num) => sum + num, 0)
      );
    datasets.length && chart.update();
  }
};

interface IJsonPieChartConfiguration {
  numericColumnName: string,
  labelColumnName: string,
  numericGroupingOpeartion?: 'sum' | 'diff' | 'avg' | 'min' | 'max'
}

interface IJsonBarChartConfiguration {
  xAxisGroupingColumnName: string,
  XAxisStackColumnName: string,
  yAxisColumnName: string,
  numericGroupingOpeartion?: 'sum' | 'diff' | 'avg' | 'min' | 'max',
  isAreaChart?: boolean,
  isStacked?: boolean
  customBarThickness?: boolean
}