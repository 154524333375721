<div class="absolute right-0 z-50 p-2 top-16 h-fit overflow-y-scroll">
    <div *ngIf="toastData.length > 0" 
        class="my-4 flex justify-center text-center items-center w-full transition-all duration-100 bg-opacity-80 backdrop-blur-md border text-black rounded-xl shadow-md shadow-gray-300 bg-white hover:bg-red-500 hover:bg-opacity-70 hover:text-white px-4 py-1 cursor-pointer" role="alert"
        (click)="closeAllToast()">
        <ng-icon class="mx-2" name="bootstrapXCircle" />
        <div class="mx-2">Close all</div>
    </div>
    <div *ngFor="let toast of toastData" class="py-1">
        <div
            class="max-w-xs transition-all duration-300 bg-opacity-80 backdrop-blur-md border text-black rounded-xl shadow-md shadow-gray-300 bg-white border-gray-100" role="alert">
            <div class="grid w-full grid-cols-5">
                <div class="flex p-4 col-span-4">
                    <div class="flex-shrink-0 shadow-lg shadow-slate-50/30" *ngIf="toast.type == TOAST_TYPE.INFO">
                        <svg class="flex-shrink-0 h-4 w-4 text-blue-500 mt-0.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                        </svg>
                    </div>
                    <div class="flex-shrink-0" *ngIf="toast.type == TOAST_TYPE.SUCCESS">
                        <svg class="flex-shrink-0 h-4 w-4 text-teal-500 mt-0.5  shadow-lg shadow-slate-50/30" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                        </svg>
                    </div>
                    <div class="flex-shrink-0" *ngIf="toast.type == TOAST_TYPE.ERROR">
                        <svg class="flex-shrink-0 h-4 w-4 text-red-500 mt-0.5 shadow-lg shadow-slate-50/30" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                        </svg>
                    </div>
                    <div class="flex-shrink-0" *ngIf="toast.type == TOAST_TYPE.WARN">
                        <svg class="flex-shrink-0 h-4 w-4 text-yellow-500 mt-0.5 shadow-lg shadow-slate-50/30" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                        </svg>
                    </div>
                    <div class="ms-3 w-full flex justify-center text-black" [innerHTML]="toast.message">
                        <!-- <p class="text-white w-full flex justify-center">
                            {{toast.message}}
                        </p> -->
                    </div>
                </div>
                <div class="col-span-1 text-black flex flex-col items-center justify-center text-center cursor-pointer group hover:bg-red-500 hover:bg-opacity-80 hover:text-white "
                    (click)="closeToast(toast.id)">
                    <div>
                        <ng-icon name="bootstrapXCircle" />
                    </div>
                    <div class="text-sm py-1 hidden group-hover:block">close</div>
                </div>
            </div>
        </div>
    </div>
  </div>