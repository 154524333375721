<div class="max-h-[50vh] max-w-[75vw] min-w-[25vw] overflow-auto">
    <div class="overflow-hidden rounded-lg bg-white shadow-xl">
        <div class="flex justify-between m-4">
            <div>
                <h3 class="text-lg font-medium text-secondary-900" *ngIf="data?.title">{{data?.title}}</h3>
            </div>
            <div>
                <button type="button" (click)="close(false)"
                    class="text-center text-secondary-500 transition-all hover:bg-secondary-100">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-6 w-6">
                        <path
                            d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                    </svg>
                </button>
            </div>
        </div>
        <div class="ml-4 mr-5 my-8" [innerHtml]="data?.content"></div>
    </div>
</div>