<div>
    <label [for]="elementId" class="mb-1 block text-sm font-medium text-gray-700">
      {{name}}
      <span class="text-red-600" *ngIf="showAsterisk">*</span>
    </label>
    <textarea [id]="elementId"
           (keyup)="updateChange($event)"
           class="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-400 focus:ring focus:ring-primary-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 {{additionalClasses}}"
           [disabled]="isDisabled"
           [value]="value"
           [placeholder]="placeholder"
           [readOnly]="readOnly"
           [class.bg-gray-100]="readOnly"></textarea>
  </div>
  