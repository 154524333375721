import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { USER_TYPES } from '../../enums/userTypes.enum';
import { UserStore } from '../../store/user/user.store';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Directive({
  selector: '[prismRoleHandler]',
})
export class RoleHandlerDirective implements OnInit {
  @Select(UserStore.getUserRoles) currentUserRole$!: Observable<string>;
  @Input() prismRoleHandler: USER_TYPES[] = [];

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<unknown>,
  ) {}

  ngOnInit() {
    this.currentUserRole$.subscribe((res) => {
      this.viewContainerRef.clear();
      if (this.prismRoleHandler?.length == 0 || this.prismRoleHandler.includes(res as USER_TYPES)) {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainerRef.clear();
      }
    });
  }
}
