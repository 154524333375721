import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { camelCase, cloneDeep } from 'lodash';
import humps from 'humps';
import { IDrillDownTableData } from '../interface/table.interface';

export const sortArray = <T>(array: Array<T>, key?: any): Array<T>  => {
  let arrayCopy = cloneDeep(array);
  arrayCopy.sort((a, b) => {
    const groupNameA = (key ? a[key] : a).toLowerCase();
    const groupNameB = (key ? b[key] : a).toLowerCase();
    return groupNameA < groupNameB ? -1 : (groupNameA > groupNameB ? 1 : 0)
  });
  return arrayCopy;
}

export const getNestedErrors = (control: any, errorKey: string): string[] => {
  let errorMessages = [];
  if (control.valid) return errorMessages;
  else if (control instanceof FormArray) {
    control.errors &&
      control.errors[errorKey] &&
      errorMessages.push(control.errors[errorKey]);
    errorMessages.push(
      ...(control as FormArray).controls
        .map((control) => {
          return getNestedErrors(control as any, errorKey).flat();
        })
        .flat(),
    );
    return errorMessages.flat();
  } else if (control instanceof FormGroup) {
    control.errors &&
      control.errors[errorKey] &&
      errorMessages.push(control.errors[errorKey]);
    errorMessages.push(
      ...Object.keys(control.controls)
        .map((key) => {
          return getNestedErrors(control.controls[key] as any, errorKey).flat();
        })
        .flat(),
    );
    return errorMessages.flat();
  } else if (control instanceof FormControl) {
    control.errors &&
      control.errors[errorKey] &&
      errorMessages.push(control.errors[errorKey]);
    return errorMessages.flat();
  } else {
    return errorMessages.flat();
  }
};

export const jsonArrayDataGrouper = (jsonArrayData: { [key: string] : string | number }[], config: IJsonArrayDataGrouperConfig) => {
  let allKeysFromJsonArray = Array.from(new Set(jsonArrayData.map(record => Object.keys(record)).flat()));
  let keyDefinitions: { key: string, type: 'string' | 'number' }[] = allKeysFromJsonArray.map(key => {
    let firstValue = jsonArrayData.find(record => record[key] != null && record[key] != undefined)!;
    return { key, type: isNaN(+firstValue[key]) ? 'string' : ( firstValue[key].toString().trim() == '' ? 'string' : 'number') }
  });
  let textKeys = keyDefinitions
    .filter(rec => (rec.type == 'string' || config.columns.find(col => col.columnName == rec.key)?.groupingOperation == undefined) && config.columns.map(record => record.columnName).includes(rec.key))
    .map(rec => rec.key);
  let numberKeys = keyDefinitions
    .filter(rec => (rec.type == 'number' && config.columns.find(col => col.columnName == rec.key)?.groupingOperation != undefined) && config.columns.map(record => record.columnName).includes(rec.key))
    .map(rec => rec.key);
  let groupedData: { [key: string]: any } = {};
  jsonArrayData.forEach(record => {
    let groupKey = textKeys.map(key => record[key]).join('-');
    if(Object.keys(groupedData).includes(groupKey)) {
      numberKeys.forEach(key => {
        let colConfig = config.columns.find(col => col.columnName == key);
        let alias = (colConfig.alias || key)
        switch(colConfig.groupingOperation) {
          case 'avg': {
            groupedData[groupKey][alias] = (+groupedData[groupKey][alias] + +record[key])/2;
            break;
          }
          case 'diff': {
            groupedData[groupKey][alias] = (+groupedData[groupKey][alias] - +record[key]);
            break;
          }
          case 'min': {
            groupedData[groupKey][alias] = +groupedData[groupKey][alias] < +record[key] ? +groupedData[groupKey][alias] : +record[key];
            break;
          }
          case 'max': {
            groupedData[groupKey][alias] = +groupedData[groupKey][alias] > +record[key] ? +groupedData[groupKey][alias] : +record[key];
            break;
          }
          case 'sum':
          default: {
            groupedData[groupKey][alias] = (+groupedData[groupKey][alias] + +record[key]);
            break;
          }
        }
      })
    } else {
      let valueObject = {};
      config.columns.forEach(col => {
        valueObject[col.alias || col.columnName] = record[col.columnName];
      });
      groupedData[groupKey] = valueObject
    }
  });
  return Object.values(groupedData);
}

export const jsonKeyRefractor = (jsonData: { [key: string]: any }[], keyRefractorFunction: (...args: any) => any) => {
  let refractoredJsonData = [];
  jsonData.forEach(record => {
    let data = {};
    Object.keys(record).forEach(key => {
      data[keyRefractorFunction(key)] = record[key];
    })
    refractoredJsonData.push(data);
  })
  return refractoredJsonData;
}

export const covertYearMonthToReadableMonthYear = (yearMonth: string) => {
  const monthNumber = parseInt(yearMonth.slice(4), 10);
  const yearNumber = parseInt(yearMonth.slice(0, 4), 10);
  const date = new Date();
  date.setDate(1);
  date.setFullYear(yearNumber)
  date.setMonth(monthNumber - 1);
  return date.toLocaleString('en-US', {
      month: 'short',
      year: 'numeric'
  });
}

export const covertYearMonthToReadableMonth = (yearMonth: string) => {
  const monthNumber = parseInt(yearMonth.slice(4), 10);
  const date = new Date();
  date.setDate(1);
  date.setMonth(monthNumber - 1);
  return date.toLocaleString('en-US', {
      month: 'long',
  });
}

export const getReadableMonthFromMonthNumber = (month: string) => {
  const monthNumber = parseInt(month);
  const date = new Date();
  date.setDate(1);
  date.setMonth(monthNumber - 1);
  return date.toLocaleString('en-US', {
      month: 'long',
  });
}

export const keysToCamelCase = (obj: Object) => {
  return humps.camelizeKeys(obj);
}

export const copyToClipboard = (item: string) => {
  document.addEventListener('copy', (e: ClipboardEvent) => {
    e.clipboardData.setData('text/plain', (item));
    e.preventDefault();
    document.removeEventListener('copy', null);
  });
  document.execCommand('copy');
}

export const convertBytesToReadableString = (
  value: string, 
  suffix: string = 'bytes'
) => {
  let handler = {
    'bytes': 'kb',
    'kb': 'mb',
    'mb': 'gb',
    'gb': 'tb',
  }
  if ((+value) < 1024) {
    return `${(+value).toFixed(suffix == 'bytes' ? 0 : 2)} ${suffix}`;
  } else {
    return convertBytesToReadableString(
      (+value / 1024).toFixed(2), 
      handler[suffix]
    );
  }
}


export interface IJsonArrayDataGrouperConfig {
  columns: {
    columnName: string,
    groupingOperation?: 'sum' | 'diff' | 'min' | 'max' | 'avg' | null,
    alias?: string,
  }[]
}