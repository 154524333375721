import { Action, Selector, State, StateContext } from '@ngxs/store';
import { IAccount, IOrganization } from '../../interface/user.interface';
import { Injectable } from '@angular/core';
import {
  AddUserDetails,
  ResetUserStore,
  SetSelectedAccount,
  SetSelectedOrganization,
  SetSelectedUserRole,
  SetSelectedSwitchedUserRole,
  SetUserDetails,
} from './user.action';
import { ALL_ACCOUNT } from '../../constants/account.constants';
import { USER_TYPES } from '../../enums/userTypes.enum';

interface IUserStore {
  displayName: string;
  accounts: Array<IAccount>;
  organizations: Array<IOrganization>;
  isPowerUser: Boolean;
  role: USER_TYPES | null;
  switchedRole: USER_TYPES | null;
  selectedAccount: IAccount | null;
  selectedOrganization: IOrganization | null;
  userId: string;
  email: string;
  phone: string;
}

@State<IUserStore>({
  name: 'userStore',
  defaults: {
    displayName: '',
    accounts: [],
    organizations: [],
    isPowerUser: false,
    role: null,
    switchedRole: null,
    selectedAccount: null,
    selectedOrganization: null,
    userId: '',
    email: '',
    phone: '',
  },
})
@Injectable()
export class UserStore {
  @Selector()
  static getUserAccounts(state: IUserStore) {
    let accounts = state.accounts;
    accounts.length > 1 &&
      accounts[0].id != ALL_ACCOUNT['id'] &&
      state.isPowerUser &&
      accounts.unshift({
        prism: ALL_ACCOUNT['prism'],
        payer_account_id: ALL_ACCOUNT['payer_account_id'],
        external_id: ALL_ACCOUNT['external_id'],
        model_id: accounts.map((m) => m.model_id).toString(),
        id: ALL_ACCOUNT['id'],
        customer_name:
          ALL_ACCOUNT['customer_name'] + ` ${state.selectedOrganization?.name}`,
        linked_account_name:
          ALL_ACCOUNT['linked_account_name'] +
          ` ${state.selectedOrganization?.name}`,
        org_id: state.selectedOrganization?.id || '',
      });
    return accounts;
  }

  @Selector()
  static getUserOrganizations(state: IUserStore) {
    return state.organizations;
  }

  @Selector()
  static getUserId(state: IUserStore) {
    return state.userId;
  }

  @Selector()
  static isPowerUser(state: IUserStore) {
    return state.isPowerUser;
  }

  @Selector()
  static isPowerUserWithSingleAccount(state: IUserStore) {
    return state.isPowerUser && state.accounts.length == 1;
  }

  @Selector()
  static getUserRoles(state: IUserStore): USER_TYPES | null {
    return state.role;
  }

  @Selector()
  static getSwitchedUserRoles(state: IUserStore): USER_TYPES | null {
    return state.switchedRole;
  }

  @Selector()
  static getUserDetails(state: IUserStore) {
    return { name: state.displayName, email: state.email, phone: state.phone, userId: state.userId };
  }

  @Selector()
  static getSelectedAccount(state: IUserStore) {
    return state.selectedAccount;
  }

  @Selector()
  static getSelectedOrganization(state: IUserStore) {
    return state.selectedOrganization;
  }

  @Action(AddUserDetails)
  addUserDetails(
    { patchState }: StateContext<IUserStore>,
    action: AddUserDetails,
  ) {
    patchState({
      displayName: action.payload.displayName,
      accounts: action.payload.accounts?.sort((a, b) =>
        a.customer_name.localeCompare(b.customer_name),
      ),
      role: USER_TYPES[action.payload.role as keyof typeof USER_TYPES] ?? null,
      isPowerUser: action.payload.isPowerUser,
      organizations: action.payload.organizations,
      selectedOrganization: action.payload.isPowerUser
        ? action.payload.organizations[0]
        : null,
      selectedAccount: action.payload.accounts[0],
      userId: action.payload.userId,
      email: action.payload.email,
      phone: action.payload.phone,
    });
  }

  @Action(SetSelectedUserRole)
  SetSelectedUserRole(
    { patchState, getState }: StateContext<IUserStore>,
    action: SetSelectedUserRole,
  ) {
    const state = getState();
    // let account = UserStore.getUserRoles(state).find(account => account.account_id === action.payload);
    patchState({
      ...state,
      role: USER_TYPES[action.payload as keyof typeof USER_TYPES],
    });
  }

  @Action(SetSelectedSwitchedUserRole)
  SetSelectedSwitchedUserRole(
    { patchState, getState }: StateContext<IUserStore>,
    action: SetSelectedSwitchedUserRole,
  ) {
    const state = getState();
    patchState({
      ...state,
      switchedRole: USER_TYPES[action.payload as keyof typeof USER_TYPES],
    });
  }

  @Action(SetSelectedAccount)
  setSelectedAccount(
    { patchState, getState }: StateContext<IUserStore>,
    action: SetSelectedAccount,
  ) {
    const state = getState();
    let account = UserStore.getUserAccounts(state).find(
      (account) => account.id === action.payload,
    );
    patchState({ ...state, selectedAccount: account });
  }

  @Action(SetSelectedOrganization)
  setSelectedOrganization(
    { patchState, getState }: StateContext<IUserStore>,
    action: SetSelectedOrganization,
  ) {
    const state = getState();
    let organization = state.organizations.find(
      (org) => org.id === action.payload.orgId,
    );
    organization.featureFlags = action.payload.featureFlags;
    let accounts = action.payload.accounts;
    accounts.length > 1 &&
      accounts[0].id != ALL_ACCOUNT['id'] &&
      state.isPowerUser &&
      accounts.unshift({
        prism: ALL_ACCOUNT['prism'],
        payer_account_id: ALL_ACCOUNT['payer_account_id'],
        external_id: ALL_ACCOUNT['external_id'],
        model_id: accounts.map((m) => m.model_id).toString(),
        id: ALL_ACCOUNT['id'],
        customer_name: ALL_ACCOUNT['customer_name'] + ` ${organization?.name}`,
        linked_account_name:
          ALL_ACCOUNT['linked_account_name'] + ` ${organization?.name}`,
        org_id: organization?.id || '',
      });
    patchState({
      ...state,
      selectedOrganization: organization,
      accounts: accounts,
      selectedAccount: accounts[0],
    });
  }

  @Action(SetUserDetails)
  setUserDetails(
    { patchState, getState }: StateContext<IUserStore>,
    action: SetUserDetails,
  ) {
    const state = getState();
    patchState({ ...state, phone: action.payload.phone, displayName: action.payload.name });
  }

  @Action(ResetUserStore)
  resetStore({ patchState }: StateContext<IUserStore>) {
    patchState({
      displayName: '',
      accounts: [],
      role: null,
      switchedRole: null,
      selectedAccount: null,
      selectedOrganization: null,
      isPowerUser: false,
      organizations: [],
      userId: '',
      phone: '',
    });
  }
}
