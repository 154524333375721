import { ApplicationRef, ComponentRef, EnvironmentInjector, Injectable, Type, createComponent } from "@angular/core";
import { ModalComponent } from "../components/modal/modal.component";
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  newModalComponent!: ComponentRef<ModalComponent>;
  newComponent!: ComponentRef<any>;
  responseObject = new Subject<any>();

  constructor(
    private appRef: ApplicationRef,
    private injector: EnvironmentInjector
  ) { }

  open(component: Type<unknown>, data: any = null) {
    this.openWithComponent(component, data);
    return this.responseObject.asObservable();
  }

  private openWithComponent(component: Type<unknown>, data: any = null) {
    this.newComponent = createComponent(component, {
      environmentInjector: this.injector,
    });
    if(this.newComponent?.instance)
      this.newComponent.instance['data'] = data;
    this.newModalComponent = createComponent(ModalComponent, {
      environmentInjector: this.injector,
      projectableNodes: [[this.newComponent.location.nativeElement]],
    });

    document.body.appendChild(this.newModalComponent.location.nativeElement);

    this.appRef.attachView(this.newComponent.hostView);
    this.appRef.attachView(this.newModalComponent.hostView);
  }

  close(response: any = null) {
    this.responseObject.next({
      response: response,
      data: this.newComponent?.instance?.data || {}
    })
    this.newModalComponent?.instance?.close();
  }
}