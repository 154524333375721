import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import {UserService} from "../services/user/user.service";

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

    constructor(private authService: UserService, private router: Router) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise((resolve, _) => {
            this.authService.isAuthenticated().then((isLoggedIn: boolean) => {
                if (!isLoggedIn) {
                    this.router.navigate(['']);
                }
                resolve(isLoggedIn);
            });
        });
    }
}
