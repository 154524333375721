

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from "@angular/router";
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const key = Object.keys(localStorage).filter(res => res.includes('accessToken'))[0]
    const token=localStorage.getItem(key)
    if (token && !request.url.includes('x-amz-security-token')) {
      request = request.clone({
        setHeaders: {
          'authorization': `Bearer ${token}`
        }
      });
    }

    return next.handle(request).pipe( tap(() => {},
      (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status == 401) {
          this.router.navigate(['']);
        }
      }
    }));
  }
}
