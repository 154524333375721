import { Component } from '@angular/core';
import { BUTTON_STYLES } from '../../../constants/core-components/prism-button.style.constants';
import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'prism-information-modal',
  templateUrl: './information-modal.component.html',
  styleUrls: ['./information-modal.component.scss'],
})
export class InformationModalComponent {
  data: any;

  BUTTON_STYLES = BUTTON_STYLES;

  constructor(private modalService: ModalService) {}

  close(res: any) {
    this.modalService.close(true);
  }
}
