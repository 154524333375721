<div class="max-w-sm w-full mx-auto" [formGroup]="loginForm">
  <div class="w-full mb-4">
    <prism-input
      placeholder="johndoe@acme.com"
      inputType="email"
      elementId="login-form-input-field"
      formControlName="email"
      additionalClasses="py-4 px-6"
      name="Email Address"
    />
    <prism-field-error [control]="loginForm.get('email')" />
  </div>
  <div class="w-full mb-4">
    <prism-input
      placeholder="Password phrase"
      name="Password"
      elementId="login-form-password-field"
      formControlName="password"
      additionalClasses="py-4 px-6"
      inputType="password"
      *ngIf="isPasswordLoginEnabled"
    />
    <prism-field-error [control]="loginForm.get('email')" />
    <div class="my-1 text-sm cursor-pointer text-ternary-400 hover:text-ternary-600"
      *ngIf="isPasswordLoginEnabled"
      (click)="navigateToAccountRecovery()">
      Forgot password?
    </div>
  </div>

  <prism-field-error
    [showCustomMessage]="true"
    additionalStyle="text-red-600 py-2"
    *ngIf="loginForm.hasError('loginError')"
  >
    {{ loginForm.getError("loginError") }}
  </prism-field-error>

  <div class="w-full">
    <div class="my-4">
      <prism-button
        (onClick)="login()"
        name="Login"
        [showLoading]="isLoading"
        additionalStyles="w-full justify-center"
      />
    </div>
    <prism-field-error
      [showCustomMessage]="true"
      additionalStyle="py-2"
      *ngIf="loginMessage && !loginForm.hasError('loginError')"
    >
      {{ loginMessage }}
    </prism-field-error>
  </div>
</div>
