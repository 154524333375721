import { IDrillDownTableData } from '../../interface/table.interface';
import { IChartDataSet } from '../../interface/aws-summary.interface';

export class AddAWSSummaryChartData {
  static readonly type = '[Dashboard] Add AWS Summary Chart';
  constructor(public payload: IChartDataSet) {}
}

export class AddPrismaCoverage {
  static readonly type = '[Dashboard] Add Prism Coverage Chart';
  constructor(public payload: IChartDataSet) {}
}
export class AddOverallSavingTrend {
  static readonly type = '[Dashboard] Add Overall Saving Trend Chart';
  constructor(public payload: IChartDataSet) {}
}
export class addAWSSummaryBasedKPI {
  static readonly type = '[Dashboard] Add aws summary based KPI Chart';
  constructor(public payload: IChartDataSet) {}
}

export class ResetDashboardStore {
  static readonly type = '[Dashboard] Reset';
}

export class AddAwsMarketplace {
  static readonly type = '[Dashboard] Add AWS Marketplace chart';
  constructor(public payload: IChartDataSet) {}
}

export class AddAwsMarketplaceItemizedSpendData {
  static readonly type = '[Dashboard] Add AWS Marketplace Itemized spend';
  constructor(public payload: IDrillDownTableData) {}
}
