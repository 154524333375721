<div class="fixed inset-0 mt-16 z-10 shadow-md bg-white bg-opacity-70 backdrop-blur-lg w-full lg:w-2/5 sm:w-3/4 left-auto right-0">
    <div class="h-full overflow-auto">
        <div class="p-4 sticky top-0 bg-white bg-opacity-70 backdrop-blur-lg">
            <div class="flex justify-between items-center">
                <div class="font-bold text-xl">{{title}}</div>
                <div class="flex space-x-4">
                    <div *ngIf="!hideCloseModal" class="text-xl cursor-pointer" (click)="closeModalIcon()"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor" class="opacity-80"><path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" /></svg></div>
                    <prism-button [additionalStyles]="BUTTON_STYLES.GHOST" *ngIf="showCancelButton"
                        [buttonStyle]="BUTTON_STYLES.GHOST" name="Cancel" (onClick)="cancelModalIcon()" />
                    <prism-button [additionalStyles]="BUTTON_STYLES.ICON" *ngIf="showApplyButton"
                        [buttonStyle]="BUTTON_STYLES.PLAIN_CYAN" name="Apply" (onClick)="applyModalIcon()" />
                </div>
            </div>
        </div>
        <div class="p-4 h-full">
            <ng-content></ng-content>
        </div>
    </div>
</div>
