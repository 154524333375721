export const LocalStorageUtil = {
    /**
     * Store Item in Local storage with a TTL
     * @param key Key on which LocalStorage has to store the value
     * @param value The value of item to be stores
     * @param ttl Expiration Time in seconds until when item is valid
     */
    setItemWithExpiry: (key: string, value: any, ttl: number) => {
        let item: any = {
            value: value
        }
        localStorage.setItem(key, JSON.stringify(item))
        const now = new Date();
        let ttlKey = `prism-${key}-expiry`
        item = {
            expiry: now.getTime() + (ttl * 1000),
        }
        localStorage.setItem(ttlKey, JSON.stringify(item))
        
    },
    
    /**
     * Get Local storage item
     * @param {string} key Item key that has to be retrieved
     * @returns {any} object value
     */
    getItemWithExpiry: (key: string): any => {
        let ttlKey = `prism-${key}-expiry`;
        const expiryStr = localStorage.getItem(ttlKey);
        if(!expiryStr) return null;
        const now = new Date()
        if (now.getTime() > JSON.parse(expiryStr)?.expiry) {
            localStorage.removeItem(key)
            return null
        }
        const itemStr = localStorage.getItem(key);
        if (!itemStr) {
            return null
        }
        const item = JSON.parse(itemStr);
        return item.value;
    },

    /**
     * Store Item in map object in Local storage with a TTL
     * @param {string} key Key on which LocalStorage has to store the value
     * @param {string} mapKey Key on which the data is stored in map
     * @param {any} value The value of item to be stores
     * @param {number} ttl Expiration Time in seconds until when item is valid
     */
    addItemInMapWithExpiry: (key: string, mapKey: string, value: any, ttl: number, mapMaxSize: number = 10) => {
        let localStorageObject: {[key in string]: {value: any, ttl: number}} | string | null = localStorage.getItem(key);
        if(localStorageObject && typeof localStorageObject == 'string') {
            localStorageObject = JSON.parse(localStorageObject as string)
        } else {
            localStorageObject = {};
        }
        const now = new Date();
        let item: any = {
            value: value,
            ttl: now.getTime() + (ttl * 1000)
        }
        if(Object.keys(localStorageObject).length >= mapMaxSize) {
            //Removing old ttl value
            const lowestKey = Object.keys(localStorageObject)
                .find(key => (localStorageObject[key].ttl || 0) === (Math.min(...Object.values(localStorageObject).map(obj => obj.ttl)) || 0));
            if(lowestKey) delete localStorageObject[lowestKey]
        }
        localStorageObject[mapKey] = item;
        localStorage.setItem(key, JSON.stringify(localStorageObject))
        let ttlKey = `prism-${key}-expiry`
        item = {
            expiry: now.getTime() + (60 * 60 * 1000),
        }
        localStorage.removeItem(ttlKey);
        localStorage.setItem(ttlKey, JSON.stringify(item));
    },

    /**
     * Get map item in Local storage
     * @param {string} key Item key that has to be retrieved
     * @param {string} mapKey Key on which the data is stored in map
     * @returns {any} object value
     */
    getItemInMapWithExpiry: (key: string, mapKey: string): any => {
        let ttlKey = `prism-${key}-expiry`;
        const expiryStr = localStorage.getItem(ttlKey);
        if(!expiryStr) return null;
        const now = new Date()
        if (now.getTime() > JSON.parse(expiryStr)?.expiry) {
            localStorage.removeItem(key)
            return null
        }
        const itemStr = localStorage.getItem(key);
        if (!itemStr) {
            return null
        }
        const map = JSON.parse(itemStr) as {[key in string]: {value: any, ttl: number}};
        if(!map[mapKey]) return null;
        if (now.getTime() > map[mapKey].ttl) {
            delete map[mapKey];
            localStorage.setItem(key, JSON.stringify(map));
            return null;
        }
        return map[mapKey].value;
    },

    /**
     * remove map item in Local storage
     * @param {string} key Item key that has to be retrieved
     * @param {string} mapKey Key on which the data is stored in map
     * @returns {any} object value
     */
    removeItemInMapWithExpiry: (key: string, mapKey: string): any => {
        let ttlKey = `prism-${key}-expiry`;
        const expiryStr = localStorage.getItem(ttlKey);
        if(!expiryStr) return null;
        const now = new Date()
        if (now.getTime() > JSON.parse(expiryStr)?.expiry) {
            localStorage.removeItem(key)
            return null
        }
        const itemStr = localStorage.getItem(key);
        if (!itemStr) {
            return null
        }
        const map = JSON.parse(itemStr) as {[key in string]: {value: any, ttl: number}};
        if(!map[mapKey]) return null;
        delete map[mapKey];
        localStorage.setItem(key, JSON.stringify(map));
        return true;
    },

    /**
     * Clear all local storage items dealing with PRISM
     */
    clearAllItemsWithKeyword: (key: string = 'prism') => {
        for (let i = 0; i < localStorage.length; i++) {
            const localKey = localStorage.key(i);
            if (localKey && localKey.includes(key)) {
              localStorage.removeItem(localKey);
            }
        }
    }
}