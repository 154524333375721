import { Injectable } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { uniqueId } from 'lodash';
import { Observable, distinctUntilChanged } from 'rxjs';
import { TOAST_TYPE } from '../../enums/toastType.enum';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  data: FormArray;

  constructor(private fb: FormBuilder) {
    this.data = fb.array([]);
  }

  toast(
    message: string,
    type: TOAST_TYPE = TOAST_TYPE.INFO,
    delay: number = 2000,
  ) {
    let id = uniqueId();
    if (type == TOAST_TYPE.ERROR) delay = 10000;
    let itemIndex = this.data.value.findIndex(record => record.message == message);
    if(itemIndex >= 0) {
      this.data.removeAt(itemIndex);
    } 
    this.data.push(
      this.fb.group({ id: id, message: message, delay: delay, type: type }),
    );
    setTimeout(
      () =>
        this.data.value.findIndex((d: any) => d.id == id) >= 0 &&
        this.data.removeAt(this.data.value.findIndex((d: any) => d.id == id)),
      delay,
    );
  }

  getToastData() {
    return this.data.valueChanges as Observable<
      {
        id: string;
        message: string;
        delay: number;
        type: TOAST_TYPE;
      }[]
    >;
  }

  closeToast(toastId: string) {
    this.data.removeAt(this.data.value.findIndex((d: any) => d.id == toastId));
  }
}
