import { COLUMN_FUNCTIONS } from '../enums/sql.enum';

export const REPORT_FILTERS_CONSTRAINTS: { [key in string]: string[] } = {
  date: ['exact', 'not exact', 'before', 'after'],
  string: [
    'exact',
    'not exact',
    'ends with',
    'starts with',
    'contains',
    'not contains',
    'null',
    'not null',
  ],
  number: [
    'exact',
    'not exact',
    'greater than',
    'lesser than',
    'less than equal to',
    'greater than equal to',
    'null',
    'not null',
  ],
  boolean: ['true', 'false'],
  json: [
    'exact',
    'not exact',
    'ends with',
    'starts with',
    'contains',
    'not contains',
    'null',
    'not null',
  ]
};

export const REPORT_RANGES = {
  Hour: {
    TODAY: 'Today',
    YESTERDAY: 'Yesterday',
    CUSTOM: 'Custom',
  },
  Day: {
    LAST_7_DAYS: 'Last 7 days',
    LAST_2_WEEKS: 'Last 2 weeks',
    CURRENT_MONTH: 'Current month',
    PREVIOUS_MONTH: 'Previous month',
    CUSTOM: 'Custom',
  },
  Week: {
    CURRENT_MONTH: 'Current month',
    PREVIOUS_MONTH: 'Previous month',
    CURRENT_PREVIOUS_MONTH: 'Current + Last month',
    LAST_2_MONTHS: 'Current + Last 2 months',
    LAST_3_MONTHS: 'Current + Last 3 months',
    CUSTOM: 'Custom',
  },
  Month: {
    CURRENT_MONTH: 'Current month',
    PREVIOUS_MONTH: 'Previous month',
    CURRENT_PREVIOUS_MONTH: 'Current + Last month',
    LAST_2_MONTHS: 'Current + Last 2 months',
    LAST_3_MONTHS: 'Current + Last 3 months',
    LAST_6_MONTHS: 'Current + Last 6 months',
    CUSTOM: 'Custom',
  },
  Year: {
    CURRENT_YEAR: 'Current year',
    LAST_YEAR: 'Last year',
    CURRENT_AND_LAST_YEAR: 'Current + Last year',
    CUSTOM: 'Custom',
  },
};

export const SQL_COLUMN_FUNCTIONS = {
  date: {
    FUNCTIONS: [
      {
        function: COLUMN_FUNCTIONS.DATE_FORMAT,
        params: [
          {
            title: 'Format',
            type: 'select',
            options: [
              { name: 'Month-Date-Year', value: '%m-%d-%Y' },
              { name: 'Date-Month-Year', value: '%d-%m-%Y' },
              { name: 'Year-Date-Month', value: '%Y-%d-%m' },
              { name: 'Year-Month-Date', value: '%Y-%m-%d' },
            ],
          },
        ],
      },
      {
        function: COLUMN_FUNCTIONS.EXTRACT,
        params: [
          {
            title: 'Constraint',
            type: 'select',
            options: [
              { name: 'Year', value: 'Year' },
              { name: 'Quarter', value: 'quarter' },
              { name: 'Month', value: 'month' },
              { name: 'Week', value: 'week' },
              { name: 'Day', value: 'day' },
              { name: 'Day of month', value: 'day of month' },
              { name: 'Day of week', value: 'day of week' },
              { name: 'Day of year', value: 'day of year' },
              { name: 'Year of week', value: 'year of week' },
              { name: 'Hour', value: 'hour' },
              { name: 'Minute', value: 'minute' },
              { name: 'Second', value: 'second' },
            ],
          },
        ],
      },
    ],
  },
  string: {
    FUNCTIONS: [
      { function: COLUMN_FUNCTIONS.LENGTH, params: [] },
      { function: COLUMN_FUNCTIONS.LOWER, params: [] },
      {
        function: COLUMN_FUNCTIONS.LPAD,
        params: [
          { title: 'Padding Text', type: 'string' },
          { title: 'length', type: 'number' },
        ],
      },
      { function: COLUMN_FUNCTIONS.LTRIM, params: [] },
      {
        function: COLUMN_FUNCTIONS.REPLACE,
        params: [
          { title: 'Text', type: 'string' },
          { title: 'Replace As', type: 'string' },
        ],
      },
      { function: COLUMN_FUNCTIONS.REVERSE, params: [] },
      {
        function: COLUMN_FUNCTIONS.RPAD,
        params: [
          { title: 'Padding Text', type: 'string' },
          { title: 'length', type: 'number' },
        ],
      },
      { function: COLUMN_FUNCTIONS.RTRIM, params: [] },
      {
        function: COLUMN_FUNCTIONS.SPLIT_PART,
        params: [
          { title: 'Delimiter', type: 'string' },
          { title: 'Index', type: 'number' },
        ],
      },
      {
        function: COLUMN_FUNCTIONS.SUBSTR,
        params: [
          { title: 'From index', type: 'number' },
          { title: 'To index', type: 'number' },
        ],
      },
      { function: COLUMN_FUNCTIONS.TRIM, params: [] },
      { function: COLUMN_FUNCTIONS.UPPER, params: [] },
    ],
  },
  number: {},
  boolean: {},
};

export const REPORT_SUBSCRIPTION_PERIOD = {
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  YEARLY: 'Yearly',
};

export const REPORT_SUBSCRIPTION_EVERY = {
  Daily: {},
  Weekly: {
    ONE_WEEK_ONCE: '1 week once',
    TWO_WEEK_ONCE: '2 weeks once',
  },
  Monthly: {
    ONE_MONTH_ONCE: '1 month once',
    TWO_MONTH_ONCE: '2 months once',
    THREE_MONTH_ONCE: '3 months once',
    SIX_MONTH_ONCE: '6 months once',
  },
  Yearly: {},
};
export enum PRISM_REPORT_STATUS {
  COMPLETED = 'completed',
  IN_PROGRESS = 'in-progress',
  ERROR = 'error',
  READY = 'ready',
  DRAFT = 'draft',
}

export const REPORT_STATUS_CHIP_STYLES = {
  COMPLETED:
    'border border-green-100 bg-green-100 text-center text-green-600 rounded-lg px-2 py-1',
  IN_PROGRESS:
    'border border-blue-100 bg-blue-100 text-center text-blue-600 rounded-lg px-2 py-1',
  ERROR:
    'border border-red-100 bg-red-100 text-center text-red-600 rounded-lg px-2 py-1',
  READY:
    'border border-cyan-100 bg-cyan-100 text-center text-cyan-600 rounded-lg px-2 py-1',
  DRAFT:
    'border border-yellow-100 bg-yellow-100 text-center text-yellow-600 rounded-lg px-2 py-1',
};

export const REPORT_CACHE_KEYS = {
  TTL: 60 * 60 * 24,
  DATASOURCE_LIST: 'prism-reports-datasources-list',
  DATASOURCE_DETAILS: 'prism-reports-datasource-detail-',
  REPORT_LISTING: 'prism-reports-list-',
  TEMPLATE_LISTING: 'prism-template-list',
  REPORT_DETAILS: 'prism-report-details',
  TEMPLATE_DETAILS: 'prism-template-details',
  REPORT_FILTER_SUGGESTIONS: 'prism-reports-filters-suggestions-'
};

export const GetStatus = {
  [PRISM_REPORT_STATUS.COMPLETED]: REPORT_STATUS_CHIP_STYLES.COMPLETED,
  [PRISM_REPORT_STATUS.IN_PROGRESS]: REPORT_STATUS_CHIP_STYLES.IN_PROGRESS,
  [PRISM_REPORT_STATUS.ERROR]: REPORT_STATUS_CHIP_STYLES.ERROR,
  [PRISM_REPORT_STATUS.READY]: REPORT_STATUS_CHIP_STYLES.READY,
  [PRISM_REPORT_STATUS.DRAFT]: REPORT_STATUS_CHIP_STYLES.DRAFT,
};

export const NUMBER_ESCAPE_COLUMNS = [
  "PayerAccountId",
  "InvoiceId",
  "LineItemId",
  "ResourceId",
  "UsageAccountId",
  "RateId",
  "SubscriptionId",
  "ParentResourceId"
];