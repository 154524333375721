import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BUTTON_STYLES } from '../../constants/core-components/prism-button.style.constants';

@Component({
  selector: 'prism-options-panel',
  templateUrl: './options-panel.component.html',
  styleUrls: ['./options-panel.component.scss'],
})
export class OptionsPanelComponent {
  @Input() title: string = '';
  @Output() closeModal = new EventEmitter<boolean>();
  @Input() hideCloseModal: boolean = false;
  @Input() showApplyButton: boolean = false;
  @Output() applyModal = new EventEmitter<boolean>();
  @Input() showCancelButton: boolean = false;
  @Output() cancelModal = new EventEmitter<boolean>();

  protected BUTTON_STYLES = BUTTON_STYLES;

  closeModalIcon() {
    this.closeModal.emit(true);
  }

  applyModalIcon() {
    this.applyModal.emit(true);
  }

  cancelModalIcon() {
    this.cancelModal.emit(true);
  }
}
