export const environment = {
  STAGE: 'dev',
  AWS_SUMMARY_SERVICE:
    'https://zrkg53sqfs4kki73j2w3r5cz5q0pcnrh.lambda-url.us-east-1.on.aws',
  PRISM_COVERAGE_SERVICE:
    'https://f6nc24qhq5t35c5zaeysjt6fom0tjqqt.lambda-url.us-east-1.on.aws',
  EDP_SPEND:
    'https://bg66ud75o7bdsjkbrbd5vcqx540dgfvf.lambda-url.us-east-1.on.aws',
  MARKETPLACE_SERVICE:
    'https://mixff62kwh6gildekiuufmmbri0isgqw.lambda-url.us-east-1.on.aws',
  MARKETPLACE_ITEMIZED_SPEND_SERVICE:
    'https://5kdn7mzzt7fzxhnqbvyb7vo6b40frbrt.lambda-url.us-east-1.on.aws',
  REPORT_PREVIEW_SERVICE:
    'https://lg3wmqycddz23qi6qlnbs4n6u40atnke.lambda-url.us-east-1.on.aws',
  SERVICE_BASE_URL:
    'https://np9ii6jeih.execute-api.us-east-1.amazonaws.com/dev/api',
  S3_DOWNLOAD_URL: 
    'https://z4yusib45ygowqfavrkbhxfo2a0ccpsa.lambda-url.us-east-1.on.aws',
  REPORT_FILTERS_SUGGESTIONS_SERVICE:
    'https://zb3a3zcyl6jk4474r5uxdyui5q0dbfyn.lambda-url.us-east-1.on.aws',
  USER_POOL_ID: 'us-east-1_YL7IE9crb',
  USER_POOL_WEB_CLIENT_ID: '637i5cro1lp1k9l3e6kub493el',
  REGION: 'us-east-1',
  DOMAIN: 'prism-dev-user-pool-domain.auth.us-east-1.amazoncognito.com',
  REDIRECT_SIGNIN: 'https://prismdev.presidio.com/', // change to localhost for local development
  REDIRECT_SIGN_OUT: 'https://prismdev.presidio.com/', // change to localhost for local development
  SCOPE: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  RESPONSE_TYPE: 'code',
  TEMP_PASSWORD: "Test@123"
};
