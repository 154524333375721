import { Component, computed, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FieldErrorMessages } from '../../../constants/fieldErrorMessages.constant';
import { NgIf } from '@angular/common';

@Component({
  selector: 'prism-field-error',
  templateUrl: './prism-field-error.component.html',
  styleUrls: ['./prism-field-error.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class PrismFieldErrorComponent {
  @Input() control!: AbstractControl;
  @Input() showCustomMessage?: boolean = false;
  @Input() additionalStyle?: string = '';

  getErrors = computed((): string => {
    return this.control.touched
      ? FieldErrorMessages[Object.keys(this.control.errors)[0]]
      : '';
  });
}
