import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IDrillDownTableData } from '../../interface/table.interface';
import { cloneDeep } from 'lodash';
import { ModalService } from '../../services/modal.service';
import { TableFilterModalComponent } from '../modal/table-filter-modal/table-filter-modal.component';
import { BUTTON_STYLES } from '../../constants/core-components/prism-button.style.constants';
import { exportTableToCsv, getReadableYearMonth } from '../../utils/table.util';
// import { IReportFormConfiguration } from '../../interface/report.interface';

@Component({
  selector: 'prism-drill-down-table',
  templateUrl: './drill-down-table.component.html',
  styleUrls: ['./drill-down-table.component.scss'],
})
export class DrillDownTableComponent implements OnChanges {
  @Input() data: IDrillDownTableData | null = null;
  @Input() emptyColumnInsert: {
    text: string;
    type: string;
    allowSort: boolean;
    class: string;
    category: string;
  };
  @Input() style: 'default' | 'listing' = 'default';
  @Output() rowClickEvent = new EventEmitter<{
    [key in string]: { value: string | number; class?: string };
  }>();
  @Output() actionEvent = new EventEmitter<{
    [key in string]: { value: string | number; class?: string };
  }>();
  @Output() filterEvent = new EventEmitter<{ headerName: string; filteredValues: Array<any> }[]>();
  @Input() searchTableText = '';
  @Input() searchableHeaders: string[] = [];
  @Input() isAvailableForExport: boolean = false;
  @Input() exportToCsvHandlerFunction: Function = this.exportToCsv;
  originalDataOrder: IDrillDownTableData | null = null;
  tableData: IDrillDownTableData | null = null;
  sortedColumn: string = '';
  sortDirection: -1 | 0 | 1 = 0;
  drillPath: string = '';
  filters: { headerName: string; filteredValues: Array<any> }[] = [];
  drillNavigationSegments: {
    path: string;
    drillUpPath: string;
    tooltip: string;
  }[] = [];
  getReadableYearMonth = getReadableYearMonth;

  protected BUTTON_STYLES = BUTTON_STYLES;
  protected exportTableToCsv = exportTableToCsv;

  constructor(private modalService: ModalService) {}

  ngOnInit() {
    if (this.emptyColumnInsert)
      this.tableData.headers.push(this.emptyColumnInsert);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.tableData = cloneDeep(changes['data'].currentValue);
      this.resetSortVariables();
      this.modalService.close();
    }
  }
  setupDataUsingFilteredValues() {
    this.tableData = cloneDeep(this.data);
    this.resetSortVariables();
    this.filters.forEach((filter) => {
      this.tableData.data = this.tableData.data.filter((record) => {
        return filter?.filteredValues?.includes(
          record[filter.headerName]?.value,
        );
      });
    });
    this.filterEvent.emit(this.filters);
  }

  resetSortVariables() {
    this.originalDataOrder = null;
    this.sortedColumn = '';
    this.sortDirection = 0;
  }

  filter(columnName: string) {
    let colValues = Array.from(
      new Set(
        this.data.data
          .map((record) => record[columnName]?.value)
          .map((value) => (value == undefined ? '(blank)' : value)),
      ),
    ).sort((a = '', b = '') => a.toString().localeCompare(b));
    let modalSubscription = this.modalService
      .open(TableFilterModalComponent, {
        title: `Filter ${columnName}`,
        columnName: columnName,
        columnValues: colValues,
        filterAppliedValues:
          this.filters.find((filter) => filter.headerName == columnName)
            ?.filteredValues || colValues,
      })
      .subscribe((res) => {
        let filterObject = this.filters.find(
          (filter) => filter.headerName == columnName,
        );
        if (res.response) {
          if (!filterObject) {
            filterObject = { headerName: columnName, filteredValues: [] };
            this.filters.push(filterObject);
          }
          filterObject.filteredValues = res.response;
          if (filterObject.filteredValues.includes('(blank)'))
            filterObject.filteredValues.push(undefined);
          //Remove filter if all values are selected
          if (colValues.length == filterObject.filteredValues.length)
            this.filters = this.filters.filter(
              (col) => col.headerName != columnName,
            );
          this.setupDataUsingFilteredValues();
          modalSubscription.unsubscribe();
        }
      });
  }

  resetAllFilters() {
    this.filters = [];
    this.tableData = cloneDeep(this.data);
    this.resetSortVariables();
    this.filterEvent.emit([]);
  }

  sortByColumn(column: string) {
    //Backup for the original data order
    if (!this.originalDataOrder)
      this.originalDataOrder = cloneDeep(this.tableData);
    else this.tableData = cloneDeep(this.originalDataOrder);
    //Sorting Logic
    if (column === this.sortedColumn) {
      this.sortDirection =
        this.sortDirection == 0 ? 1 : this.sortDirection == 1 ? -1 : 0;
    } else {
      this.sortedColumn = column;
      this.sortDirection = 1;
    }
    if (this.sortDirection != 0) {
      this.tableData?.data.sort((a, b) => {
        const aValue = a[this.sortedColumn].value;
        const bValue = b[this.sortedColumn].value;

        return (aValue > bValue ? 1 : -1) * this.sortDirection;
      });
    }
  }

  selectRow(data: {
    [key in string]: { value: string | number; class?: string };
  }) {
    this.rowClickEvent.emit(data);
  }

  onButtonClick(
    data: { [key in string]: { value: string | number; class?: string } },
    action: string,
  ) {
    data['Action'].value = action;
    this.actionEvent.emit(data);
  }

  drillDown(nestedKey: string = '') {
    this.drillPath += nestedKey ? `/${nestedKey}` : '';
    this.drillNavigationSegments = this.getDrillNavigationSegments();
    let innerKeys = this.drillPath.split('/').filter((key) => key);
    let drillingData: IDrillDownTableData | null = cloneDeep(this.data);
    innerKeys.forEach((innerKey, index) => {
      drillingData =
        (drillingData.data
          ?.filter((record) => record.nestedHeaderKey)
          ?.find((record) => {
            return record[record.nestedHeaderKey as string].value == innerKey;
          })?.nestedTable as IDrillDownTableData) || null;
    });
    this.tableData = cloneDeep(drillingData);
    this.resetSortVariables();
    this.originalDataOrder = cloneDeep(drillingData);
  }

  drillUp(indexCount: number = 1) {
    let drilledPath = this.drillPath.split('/').filter((path) => path);
    drilledPath = drilledPath.splice(0, drilledPath.length - indexCount);
    this.drillPath = drilledPath.join('/');
    this.drillNavigationSegments = this.getDrillNavigationSegments();
    this.drillDown();
  }

  getDrillNavigationSegments() {
    let segments: { path: string; drillUpPath: string; tooltip: string }[] = [];
    this.drillPath
      .split('/')
      .filter((path) => path)
      .forEach((path, index) => {
        segments.push({
          path: `${path.length > 25 ? path.substring(0, 5) : path}${path.length > 25 ? '...' : ''}`,
          tooltip: path,
          drillUpPath: `${index == 0 ? '/' : ''}${this.drillPath
            .split('/')
            .filter((path) => path)
            .splice(0, index + 1)
            .join('/')}`,
        });
      });
    if (segments.length > 0)
      segments.unshift({ path: 'root', drillUpPath: '', tooltip: 'root' });
    return segments;
  }

  isFilteredHeader(headerName: string) {
    return this.filters.some((filter) => filter.headerName == headerName);
  }

  exportToCsv() {
    let blob = this.exportTableToCsv(this.data);
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'Export.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
