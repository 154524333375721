<div>
  <label [for]="elementId" class="mb-1 block text-sm font-medium text-gray-700"
         *ngIf="name">
    {{ name }}
    <span class="text-red-600" *ngIf="showAsterisk">*</span>
    <ng-icon *ngIf="showHelpIcon" name="bootstrapQuestion" prismTooltip="Help"
      class="mx-1 text-md cursor-pointer" (click)="helpIconClick.emit(true)">
    </ng-icon>
  </label>
  <div class="relative">
  <input [type]="inputType" [id]="elementId" [name]="elementId"
         (keyup)="updateChange($event)"
         class="block w-full rounded-lg border-gray-300 shadow-sm focus:border-primary-400 focus:ring focus:ring-primary-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 {{additionalClasses}}"
         [formControl]="value"
         [placeholder]="placeholder"
         [readOnly]="readOnly"
         [class.bg-gray-100]="readOnly" />
    <div *ngFor="let icon of innerIconConfig" (click)="icon.clickHandler()" class="mx-2 absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer">
      <ng-icon [name]="icon.iconName" class="text-gray-500 cursor-pointer"/>
    </div>
  </div>
</div>
