<div
  class="grid grid-cols-1 lg:grid-cols-12 h-full lg:min-h-screen overflow-y-auto"
>
  <div
    class="col-span-7 login-background px-10 relative flex items-center justify-center"
  >
    <div class="absolute top-0 left-0 p-10">
      <img
        ngSrc="/assets/images/prism-logo.svg"
        alt="Prism logo"
        height="50"
        width="120"
      />
    </div>
    <h1
      class="text-xl pt-36 pb-6 lg:py-0 lg:text-5xl font-bold w-full lg:w-2/3 text-white lg:leading-[3.5rem]"
    >
      Go all the way. <br />Don't give up. Ever. <br />It's that simple.
    </h1>
  </div>
  <div class="col-span-5">
    <div class="flex p-10 items-start justify-center flex-col h-full w-full">
      <h1
        class="text-4xl font-bold lg:leading-loose tracking-wide text-center w-full"
      >
        Welcome to PRISM
      </h1>
      <h4 class="text-2xl text-secondary-900 text-center w-full">
        Live the experience
      </h4>
      <div class="w-full mt-10">
        <prism-login-form />
      </div>
    </div>
  </div>
</div>
