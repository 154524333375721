import { Component } from '@angular/core';
import { Amplify } from 'aws-amplify';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  aws_exports = {
    userPoolId: environment.USER_POOL_ID,
    userPoolWebClientId: environment.USER_POOL_WEB_CLIENT_ID,
    region: environment.REGION,
    oauth: {
      domain: environment.DOMAIN,
      redirectSignIn: environment.REDIRECT_SIGNIN,
      redirectSignOut: environment.REDIRECT_SIGN_OUT,
      scope: environment.SCOPE,
      responseType: environment.RESPONSE_TYPE,
    },
  };

  constructor() {
    Amplify.configure(this.aws_exports);
  }
}
