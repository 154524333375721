export const TOAST_MESSAGES = {
  ROLE_SWITCH_MESSAGE: (roleName: string) =>
    `You are acting as <br>${roleName} user`,
  OPEN_CREATED_REPORT_SCREEN: `Opening Report...`,
  OPEN_NEW_REPORT_SCREEN: `Preparing screens...`,
  OPEN_TEMPLATE_SCREEN: `Opening Template...`,
  REPORT_TRIGGER_SUCCESS: `Report job triggered Successfully`,
  REPORT_SUBSCRIBE_SUCCESS: `Subscribed successfully`,
  REPORT_UNSUBSCRIBE_SUCCESS: `Un-Subscribed successfully`,
  INVALID_REQUEST: `Invalid Request`,
  INVALID_INVITATION: `Invitation expired / invalid`,
  USER_INVITE_SUCCESS: `User invited`,
  USER_DELETE_SUCCESS: `User deleted successfully`,
};

export const DASHBOARD = {
  CHART: {
    TOTAL_AWS_SAVINGS: {
      LEGENDS: {
        AWS: 'AWS OnDemand Cost',
        PRISM: 'Cost after Discounts (EDP, SP, RI, PRISM etc)',
        PRISM_SAVINGS: 'Discount / Savings',
      },
    },
    PRISM_SAVINGS: {
      GROUP: {
        AWS: 'AWS OnDemand Cost',
        PRISM: 'Cost after PRISM Discounts',
      },
    },
    EDP_COMMITMENT: {
      LEGENDS: {
        COMMIT: 'Contract Usage Commitment',
        ACTUAL: 'Actual Usage Cost',
        MARKETPLACE: 'Marketplace Usage Cost',
      },
    },
  },
};

export enum INFO_MESSAGES {
  NO_EDP_DATA_FOUND = 'NO_EDP_DATA_FOUND',
}
