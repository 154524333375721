import { Component } from '@angular/core';
import { BUTTON_STYLES } from '../../../constants/core-components/prism-button.style.constants';
import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'prism-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {
  data: any;

  BUTTON_STYLES = BUTTON_STYLES;

  constructor(private modalService: ModalService) {}

  close(res: any) {
    this.modalService.close(res);
  }
}
