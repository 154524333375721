import { Component } from '@angular/core';
import { TOAST_TYPE } from '../../enums/toastType.enum';
import { ToastService } from '../../services/toast/toast.service';

@Component({
  selector: 'prism-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent {
  protected TOAST_TYPE = TOAST_TYPE;

  toastData: {
    id: string;
    message: string;
    delay: number;
    type: TOAST_TYPE;
  }[] = [];
  constructor(private toastService: ToastService) {
    this.toastService.getToastData().subscribe((data) => {
      this.toastData = data;
    });
  }

  closeToast(toastId: string) {
    this.toastService.closeToast(toastId);
  }

  closeAllToast() {
    this.toastData.forEach(toast => this.closeToast(toast.id));
  }

}
