export enum COLUMN_DATA_TYPE {
    DATE = 'date',
    STRING = 'string',
    NUMBER = 'number',
    BOOLEAN = 'boolean'
}

export enum COLUMN_FUNCTIONS {

    /** Date Functions */
    DATE_FORMAT = 'Date Format',
    EXTRACT = 'Extract',

    /** String Functions */
    LENGTH = 'Length',
    LOWER = 'Lowercase',
    LPAD = 'Left pad text',
    LTRIM = 'Remove Leading whitespace',
    SUBSTR = 'Sub-string',
    REPLACE = 'Replace sub-text',
    REVERSE = 'Reverse',
    RPAD = 'Right pad text',
    RTRIM = 'Remove trailing whitespace',
    SPLIT_PART = 'Split string',
    TRIM = 'Trim',
    UPPER = 'Uppercase'

    /** Number Functions */
    

    /** Boolean Functions */

}