import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  IAccount,
  ICrossCloudConfiguration,
  IOrganizationFeatureFlag,
  IUser,
} from '../../interface/user.interface';
import { catchError, mergeMap, Observable, of, throwError } from 'rxjs';
import { FEATURE_FLAG } from '../../enums/featureFlag.enum';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private httpService: HttpClient) {}

  checkAndRegisterSSOUser(email: string) {
    const url = `${environment.SERVICE_BASE_URL}/check-and-reg-ssouser`;
    return this.httpService.post(url, { email });
  }

  getUserDetails(): Observable<IUser | null> {
    let url = `${environment.SERVICE_BASE_URL}/get-userinfo`;
    return this.httpService.get<IUser>(url).pipe(
      mergeMap((res) => {
        res.organizations = res.organizations?.sort((a, b) =>
          a.name.localeCompare(b.name),
        );
        res.accounts = res.accounts?.sort((a, b) =>
          a.customer_name.localeCompare(b.customer_name),
        );
        if (res.isPowerUser) {
          url = `${environment.SERVICE_BASE_URL}/get-organization-accounts`;
          let orgId = res.organizations[0]?.id || '';
          url += `?orgId=${orgId}`;
          return this.httpService.get<IAccount[]>(url).pipe(
            mergeMap((accountData) => {
              res.accounts = accountData;
              return this.getOrganizationalFlagForOrganizationId(orgId, res);
            }),
            catchError((error: any) => {
              return of(res || null);
            }),
          );
        }
        return of(res);
      }),
      catchError((error: any) => {
        if(error?.error?.message?.includes('Account is locked')) 
            return throwError(() => new Error(error?.error?.message));
        else return of(null);
      }),
    );
  }

  getAccountsForOrganizationMasterId(orgId: string): Observable<{
    accounts: Array<IAccount> | null;
    featureFlags: FEATURE_FLAG[];
  } | null> {
    const url = `${environment.SERVICE_BASE_URL}/get-organization-accounts?orgId=${orgId}`;
    return this.httpService.get<Array<IAccount>>(url).pipe(
      mergeMap((res) => {
        let response = { accounts: null, featureFlags: [] };
        response.accounts = res?.sort((a, b) =>
          a.customer_name.localeCompare(b.customer_name),
        );
        return this.getOrganizationalFlagForOrganizationId(
          orgId,
          response,
          false,
        );
      }),
      catchError((error: any) => {
        return of(null);
      }),
    );
  }

  updateUserDetails(details: {userId: string, name: string, phone: string, email: string}) {
    const url = `${environment.SERVICE_BASE_URL}/update-user-details`;
    return this.httpService.post(url, details);
  }

  private getOrganizationalFlagForOrganizationId(
    organizationId: string,
    response: any,
    isOrganizationRequest: boolean = true,
  ) {
    let featureFlagUrl = `${environment.SERVICE_BASE_URL}/get-feature-flags-for-organization?organizationId=${organizationId}`;
    return this.httpService.get<IOrganizationFeatureFlag>(featureFlagUrl).pipe(
      mergeMap((orgFeatureFlag) => {
        if (isOrganizationRequest) {
          response.organizations[0].featureFlags =
            orgFeatureFlag.featureFlags || [];
        } else {
          response.featureFlags = orgFeatureFlag.featureFlags || [];
        }
        return of(response);
      }),
      catchError((error: any) => {
        return of(response || null);
      }),
    );
  }

  isAuthenticated(): Promise<boolean> {
    return new Promise((resolve, _) => {
      Auth.currentAuthenticatedUser()
        .then((response) => {
          if (response) {
            resolve(true);
          }
        })
        .catch((_) => {
          resolve(false);
        });
    });
  }

  getUsernameInCognitoUsingEmail(email: string): Observable<string | null> {
    const url = `${environment.SERVICE_BASE_URL}/search-user-in-cognito-using-email?email=${email}`;
    return this.httpService.get<string>(url)
      .pipe(
        mergeMap((userName) => of(userName)),
        catchError(() => of(null))
      );
  }

  getCrossCloudConfiguration(orgId: string, options?: { type?: string, cloud?: string }) {
    let url = `${environment.SERVICE_BASE_URL}/get-cross-account-details-of-org?orgId=${orgId}`;
    if(options?.cloud) url += `&cloud=${options.cloud}`;
    if(options?.type) url += `&type=${options.type}`;
    return this.httpService.get<ICrossCloudConfiguration[]>(url)
      .pipe(
        mergeMap((data) => of({error: null, data: data})),
        catchError((err) => of({error: err, data: [] as ICrossCloudConfiguration[]}))
      );
  }

  deleteCrossCloudConfiguration(id: number) {
    const url = `${environment.SERVICE_BASE_URL}/delete-cross-account-details?id=${id}`;
    return this.httpService.delete<boolean>(url)
      .pipe(
        mergeMap((data) => of({error: null, data: data})),
        catchError((err) => of({error: err, data: false}))
      );
  }

  upsertCrossCloudConfiguration(data: ICrossCloudConfiguration) {
    const url = `${environment.SERVICE_BASE_URL}/upsert-cross-account-details`;
    return this.httpService.post<ICrossCloudConfiguration | null>(url, data)
      .pipe(
        mergeMap((data) => of({error: null, data: data})),
        catchError((err) => of({error: err, data: null as ICrossCloudConfiguration}))
      );
  }
}
