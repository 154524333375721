import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { LoaderComponent } from '../../components/loader/loader.component';
import { AmountPipe } from '../../pipes/amount/amount.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { DrillDownTableComponent } from '../../components/drill-down-table/drill-down-table.component';
import { OptionsPanelComponent } from '../../components/options-panel/options-panel.component';
import { SearchPipe } from '../../pipes/search/search.pipe';
import { ToastComponent } from '../../components/toast/toast.component';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { RoleHandlerDirective } from '../../directives/role-directive/role-handler.directive';
import { PrismButtonComponent } from '../../components/core/prism-button/prism-button.component';
import { NgIconsModule } from '@ng-icons/core';
import { Icons } from './icons';
import { PrismDropdownComponent } from '../../components/core/prism-dropdown/prism-dropdown.component';
import { environment } from '../../../environments/environment';
import { TooltipDirective } from '../../directives/tooltip-directive/tooltip.directive';
import { FeatureFlagDirective } from '../../directives/feature-flag-directive/feature-flag.directive';
import { DynamicDashboardComponent } from 'src/app/components/dynamic-dashboard/dynamic-dashboard.component';
import { KtdGridModule } from '@katoid/angular-grid-layout';

@NgModule({
  declarations: [
    LoaderComponent,
    AmountPipe,
    DrillDownTableComponent,
    OptionsPanelComponent,
    SearchPipe,
    ToastComponent,
    RoleHandlerDirective,
    TooltipDirective,
    FeatureFlagDirective,
    DynamicDashboardComponent
  ],
  exports: [
    LoaderComponent,
    AmountPipe,
    DrillDownTableComponent,
    OptionsPanelComponent,
    SearchPipe,
    LoggerModule,
    ToastComponent,
    RoleHandlerDirective,
    FeatureFlagDirective,
    NgIconsModule,
    PrismDropdownComponent,
    TooltipDirective,
    DynamicDashboardComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PrismButtonComponent,
    PrismDropdownComponent,
    LoggerModule.forRoot({
      level:
        environment.STAGE === 'prod'
          ? NgxLoggerLevel.ERROR
          : NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.ERROR,
    }),
    NgIconsModule.withIcons(Icons),
    KtdGridModule,
  ],
  providers: [DecimalPipe],
})
export class SharedModule {}
