import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuardService } from './guards/auth.guard';
import { ApplicationPagesComponent } from './modules/application-pages/application-pages.component';
import { CreateUserComponent } from './pages/login/create-user/create-user.component';
import { AccountRecoveryComponent } from './pages/login/account-recovery/account-recovery.component';

const routes: Routes = [
  {
    path: 'create-user/:invited-user-token-id',
    component: CreateUserComponent,
  },
  {
    path: 'account-recovery',
    component: AccountRecoveryComponent
  },
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'prism',
    loadChildren: () =>
      import('./modules/application-pages/application-pages.module').then(
        (mod) => mod.ApplicationPagesModule,
      ),
    canActivate: [AuthGuardService],
    data: { breadcrumb: { skip: true } },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
