import { CommonModule } from '@angular/common';
import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule} from "@angular/forms";

@Component({
  selector: 'prism-input',
  templateUrl: './prism-input.component.html',
  styleUrls: ['./prism-input.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PrismInputComponent),
      multi: true
    }
  ]
})
export class PrismInputComponent implements ControlValueAccessor {
  @Input() elementId!: string;
  @Input() placeholder!: string;
  @Input() name!: string;
  @Input() additionalClasses?: string;
  @Input() inputType: string = 'text';
  @Input() readOnly: boolean = false;
  @Input() showAsterisk: boolean = false;
  isDisabled: boolean = false;

  value: FormControl<any> = new FormControl(null);

  onChangeFn!: Function;
  onTouchedFn!: Function;

  writeValue(obj: any): void {
    this.value.setValue(obj);
  }

  registerOnChange(fn: Function): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  updateChange(e: any) {
    if (this.onChangeFn) {
      this.onChangeFn(e.target.value);
      this.onTouchedFn(true);
    }
  }

}
