import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { BUTTON_STYLES } from '../../../constants/core-components/prism-button.style.constants';
import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'prism-table-filter-modal',
  templateUrl: './table-filter-modal.component.html',
  styleUrls: ['./table-filter-modal.component.scss'],
})
export class TableFilterModalComponent implements OnInit {
  data: any;
  valueForm = new FormArray<
    FormGroup<{ column: FormControl<string>; selected: FormControl<boolean> }>
  >([]);
  searchText = new FormControl<string>('');

  BUTTON_STYLES = BUTTON_STYLES;

  constructor(private modalService: ModalService) {}

  ngOnInit() {
    (this.data.columnValues as string[]).forEach((column) => {
      this.valueForm.push(
        new FormGroup({
          column: new FormControl(column),
          selected: new FormControl(
            this.data?.filterAppliedValues?.includes(column) || false,
          ),
        }),
      );
    });
  }

  close(res: any) {
    this.modalService.close(res);
  }

  selectionActionForAllFilter(action: boolean = true) {
    this.valueForm.controls
      .filter((columnGroup) => {
        return this.searchText.value.trim().length > 0
          ? columnGroup.controls.column.value
              .toString()
              .toLowerCase()
              .trim()
              .includes(this.searchText.value.trim().toLowerCase())
          : true;
      })
      .forEach((columnGroup) => {
        columnGroup.controls.selected.patchValue(action);
      });
  }

  applyFilter() {
    this.close(
      this.valueForm.value
        .filter((col) => col?.selected)
        .map((col) => col.column),
    );
  }
}
