export const Colors = [
    "#9b9eff",
    "#ffc700",
    "#33d4d1",
    "#a34656",
    "#245ada",
    "#ef8d14",
    "#61bc28",
    "#d1b524",
    "#b7edff",
    "#d787f2",
    "#2a5519",
    "#f200ff",
    "#cc280e",
    "#f1ccff",
    "#a86f4c",

    "#38cb65",
    "#e44cc0",
    "#7bd960",
    "#8345c1",
    "#b0c323",
    "#578b1b",

    "#7ab52a",
    "#5073f7",


    "#3447b4",
    "#53b23c",


    "#b55edb",
    "#48c96f",
    "#fa63d5",
    "#24da8f",
    "#f82387",
    "#43ab4c",
    "#a232a7",
    "#9fb533",
    "#5c51c6",
    "#135ac2",
    "#e4bd4c",
    "#2f65d0",
    "#80b344",
    "#de79f3",

    "#c656ca",
    "#35ac5f",
    "#c41c8d",
    "#8ad172",
    "#e242a8",
    "#2c7d24",
    "#c64db2",
    "#6ccd7f",
    "#d02584",
    "#3bc185",
    "#ef489e",
    "#6eb559",
    "#6942aa",
    "#b7a52e",
    "#5569d9",
    "#eea240",
    "#6c81f4",
    "#e1651b",
    "#3295e9",

    "#35bc42",
    "#e42749",
    "#3fcaa0",
    "#d42273",
    "#539a40",
    "#d37ce6",
    "#277a35",
    "#f3478d",
    "#58a860",
    "#952f8c",
    "#a4b34c",
    "#6d53b8",


    "#bdb553",
    "#a074e2",
    "#617202",
    "#9382e9",
    "#7b8b24",
    "#9f52b6",
    "#85b159",
    "#7c46a0",
    "#57832e",
    "#e87edb",
    "#3e6914",
    "#e360b5",
    "#1f814d",
    "#e7346f",
    "#70cb92",
    "#e22959",
    "#45c6b2",
    "#ba1a20",
    "#34c7dd",
    "#d13531",
    "#60bce8",
    "#e3542e",
    "#658aed",
    "#d48122",
    "#0f5eb0",
    "#e89a4f",
    "#4158b1",
    "#c6a94d",
    "#2b6abe",
    "#ca5d26",
    "#5e90e9",
    "#77740e",
    "#ba7fdf",
    "#4b600e",
    "#b153ab",
    "#46a26c",
    "#9f0b64",
    "#8cbf79",
    "#ad2270",
    "#33a27e",
    "#f15348",
    "#53a7e8",
    "#ae301f",
    "#3e76cc",
    "#dda955",
    "#505099",
    "#9a8b2d",
    "#7155a7",
    "#b7bf6d",
    "#b03d8d",
    "#386a2a",
    "#f066ab",
    "#457636",
    "#cb428a",

    "#b12060",
    "#84c08c",
    "#c83973",
    "#6bb391",
    "#c62f4d",
    "#3fadaf",
    "#e74c53",
    "#4299c5",

    "#0362a0",
    "#cc7a34",
    "#236ead",
    "#a55a12",
    "#4787c9",
    "#8d630a",
    "#a887de",
    "#866f11",
    "#9a95e5",
    "#5e7222",
    "#dc73bc",
    "#ed5e93",

    "#cb3863",
    "#639e6c",
    "#864490",
    "#92a654",
    "#af6ab8",
    "#718535",
    "#d196e2",
    "#5b5b10",
    "#7b95de",
    "#aa7b28",

    "#d1b66e",
    "#716fbb",
    "#969448",
    "#526fb4",
    "#df714c",
    "#2178a3",
    "#c2373c",
    "#2a775f",
    "#ea616e",
    "#4d662b",
    "#e272aa",
    "#738c4e",
    "#a83c76",
    "#9ab474",
    "#972554",
    "#b9b67c",

    "#757429",
    "#d383c2",
    "#63510a",

    "#a23e1d",
    "#6f8ec2",

    "#6a74aa",
    "#e98e5d",
    "#4a5589",
    "#da9c61",
    "#65558f",
    "#a68f49",
    "#9e81c3",
    "#705d18",
    "#d89fd7",
    "#704b0c",
    "#7867a3",
    "#846f2a",
    "#9b4f8c",
    "#85874f",

    "#5e622c",
    "#e98fbe",
    "#7d5719",
    "#b57eb4",
    "#92692b",
    "#865286",
    "#b58949",
    "#996599",
    "#8f4216",
    "#d191b7",

    "#e77199",
    "#8e7c49",
    "#8d3b69",
    "#d6aa7c",
    "#793f63",
    "#edaa83",
    "#864b6f",
    "#a0622c",
    "#b6618f",
    "#7b572d",
    "#ee94b0",
    "#896338",
    "#e76681",
    "#a47c50",
    "#a83250",
    "#ed9576",
    "#7e3e53",
    "#ec7f6a",
    "#a16489",
    "#b46133",
    "#913a5a",
    "#ba7c57",
    "#a64d6c",
    "#88502e",
    "#c36785",
    "#95482f",
    "#e4969a",
    "#a13e2f",
    "#925065",
    "#ea675f",
    "#884349",
    "#e28f82",
    "#a83540",
    "#dd7a87",
    "#bf5846",
    "#b2696d",
    "#c74d60",
    "#c06e51",

    "#d26c69",
    "#296437",
    "#6d5d2c",
    "#e56047",
    "#b1abe9",
    "#7d5496",
    "#437d4e",
    "#a00000",
    "#89ff9f",
    "#ef568e",
    "#355799",
    "#e6a01e",
    "#4196a3"]
