import { NUMBER_ESCAPE_COLUMNS } from "../constants/report.constants";
import { IDrillDownTableData } from "../interface/table.interface";
import { covertYearMonthToReadableMonth, covertYearMonthToReadableMonthYear, getReadableMonthFromMonthNumber } from "./common.util";



export const convertJsonToDrilldownTable = (data: { [key in any] : any }[], options?: IJsonTableOptions): IDrillDownTableData => {
    let tableData: IDrillDownTableData = { headers: [], data: [] };
    const allKeys = [...new Set(data.flatMap(obj => Object.keys(obj)))];
    tableData.headers = allKeys.map(key => {
        let headerRecord = { text: key, ...(options?.headers[key] || {}), allowFilter: options.allowFilter || false } as any;
        if(! headerRecord.type) headerRecord.type = !isNaN(data?.flatMap(obj => obj[key])?.filter(obj => obj)[0]) && !NUMBER_ESCAPE_COLUMNS.includes(key) && !key.endsWith("Id") ? 'number' : 'string';
        if(options?.headers[key]?.alias) headerRecord.text = options.headers[key].alias;
        return options.headers[key]?.hidden ? null : headerRecord;
    }).filter(header => header);
    let nonNumericKeys = allKeys.filter(key =>
        tableData.headers
            .filter(head => head.type != 'number' && head.type != 'currency')
            .some(aliases => (options.headers[key]?.alias || key) == aliases.text)
    );
    let numericKeys = allKeys.filter(key => !nonNumericKeys.includes(key)).filter(key => !options.headers[key]?.hidden);
    let groupedData = [];
    data.forEach(record => {
        let matchingRecord = groupedData.find(rec => {
            let isMatched = true;
            nonNumericKeys.forEach(nonNumericKey => {
                isMatched = isMatched && record[nonNumericKey] == rec[nonNumericKey]
            });
            return isMatched;
        });
        if(matchingRecord) {
            numericKeys.forEach(numericKey => {
                matchingRecord[numericKey] = +matchingRecord[numericKey] + +record[numericKey];
            })
        } else {
            groupedData.push(record);
        }
    })
    tableData.data = groupedData.map(record => {
        let row = {};
        allKeys.forEach(key => {
            row[options.headers[key]?.alias || key] = { value: record[key], ...(options?.headers[key]?.cellOption || {}) }
        });
        return row;
    })
    options?.orderConfig 
        && tableData.headers.map(header => header.text).includes(options.orderConfig.headerKey) 
        && tableData.data.sort((a, b) => {
            const aValue = a[options.orderConfig.headerKey].value;
            const bValue = b[options.orderConfig.headerKey].value;

            return (aValue > bValue ? 1 : -1) * (options.orderConfig.constraint == 'asc' ? 1 : -1);
        });
    return tableData;
}

export const nestTable = (data: IDrillDownTableData, configuration: ITableNestingConfiguration, sortingConfiguration?: ITableNestingSortingConfiguration) => {
    let nestedTable: IDrillDownTableData = { headers: [], data: [] }
    data.headers
        .filter(header => Object.keys(configuration).includes(header.text))
        .forEach(header => {
            nestedTable.headers.push({
                text: configuration[header.text].alias || header.text,
                type: header.type,
                ...configuration[header.text],
            });
        });
    let textTypeHeaders: string[] = nestedTable.headers
        .filter(header => !['number', 'currency'].includes(header.type))
        .map(header => header.text);
    let numberTypeHeaders: string[] = nestedTable.headers
        .filter(header => ['number', 'currency'].includes(header.type))
        .map(header => header.text);
    const groupedData = {};
    const headerConfiguration = Object.keys(configuration).map(key => { return {header: key, ...configuration[key]} });
    data.data.forEach(record => {
        const groupKey = textTypeHeaders.map(header => record[headerConfiguration.find(hc => [hc.alias, hc.header].includes(header)).header].value).join('-');
        if (!groupedData[groupKey]) {
            groupedData[groupKey] = {};
            data.headers.filter(head => headerConfiguration
                .map(hc => hc.header).includes(head.text))
                .forEach(headerRecord => {
                    let matchingHeaderConfiguration = headerConfiguration
                        .find(hc => [hc.alias, hc.header].includes(headerRecord.text))
                    let value = record[ matchingHeaderConfiguration.header ].value;
                    groupedData[groupKey][matchingHeaderConfiguration.alias || matchingHeaderConfiguration.header] = { value }
            })
            groupedData[groupKey]['nestedHeaderKey'] = textTypeHeaders[0];
            let nestedHeaders = data.headers.filter(header =>
                !headerConfiguration.filter(header => !header.groupingFunction)
                    .map(header => header.header).includes(header.text)
            )
            let innerNestedTable: IDrillDownTableData = { headers: [], data: [] };
            innerNestedTable.headers = data.headers.filter(header => nestedHeaders.map(h => h.text).includes(header.text));
            innerNestedTable.data = data.data
                .filter(innerData => {
                    let textHeaderValues = Object.keys(configuration).filter(con => !configuration[con].groupingFunction);
                    return textHeaderValues.filter(thead => innerData[thead].value == record[thead].value).length == textHeaderValues.length
                })
                .map(dataRecord => {
                let innerRecord = {};
                innerNestedTable.headers.forEach(ih => {
                    innerRecord[ih.text] = {value: dataRecord[ih.text].value}
                })
                if(dataRecord.nestedHeaderKey && dataRecord.nestedTable) {
                    innerRecord['nestedHeaderKey'] = dataRecord.nestedHeaderKey
                    innerRecord['nestedTable'] = dataRecord.nestedTable
                }
                return innerRecord;
                })
            sortingConfiguration && sortingConfiguration.nestedTableSort 
                && innerNestedTable.headers.map(header => header.text).includes(sortingConfiguration.nestedTableSort.headerKey) 
                && innerNestedTable.data.sort((a, b) => {
                    const aValue = a[sortingConfiguration.nestedTableSort.headerKey].value;
                    const bValue = b[sortingConfiguration.nestedTableSort.headerKey].value;
        
                    return (aValue > bValue ? 1 : -1) * (sortingConfiguration.nestedTableSort.constraint == 'asc' ? 1 : -1);
                });
            groupedData[groupKey]['nestedTable'] = innerNestedTable;
            groupedData[groupKey]['expanded'] = false;
        } else {
            numberTypeHeaders.forEach(aggHeader => {
                let conf = headerConfiguration.find(hc => [hc.alias, hc.header].includes(aggHeader));
                switch(conf.groupingFunction) {
                    case 'sum': {
                        groupedData[groupKey][aggHeader].value = +groupedData[groupKey][aggHeader].value + +record[conf.header].value;
                        break;
                    }
                    case 'diff': {
                        groupedData[groupKey][aggHeader].value = +groupedData[groupKey][aggHeader].value - +record[conf.header].value;
                        break;
                    }
                    case 'avg': {
                        groupedData[groupKey][aggHeader].value = (+groupedData[groupKey][aggHeader].value + +record[conf.header].value)/2;
                        break;
                    }
                }
            })
        }
    });
    nestedTable.data = Object.values(groupedData);
    sortingConfiguration && sortingConfiguration.parentTableSort 
        && nestedTable.headers.map(header => header.text).includes(sortingConfiguration.parentTableSort.headerKey) 
        && nestedTable.data.sort((a, b) => {
            const aValue = a[sortingConfiguration.parentTableSort.headerKey].value;
            const bValue = b[sortingConfiguration.parentTableSort.headerKey].value;        
            return (aValue > bValue ? 1 : -1) * (sortingConfiguration.parentTableSort.constraint == 'asc' ? 1 : -1);
        });
    return nestedTable;
}

export const getReadableYearMonth = (yearMonth: string, headerText: string): string => {
    if(yearMonth.length == 0 || !yearMonth || isNaN(+yearMonth)) {
      return yearMonth
    }
    if(headerText.trim().toLowerCase() == 'month') {
      if(yearMonth.trim().length == 6) return covertYearMonthToReadableMonth(yearMonth);
      else if(yearMonth.length <= 2) return getReadableMonthFromMonthNumber(yearMonth);
      else return yearMonth;
    } else if (headerText.trim().toLowerCase() == 'period') {
      if(yearMonth.trim().length == 6) return covertYearMonthToReadableMonthYear(yearMonth);
      else if(yearMonth.length <= 2) return getReadableMonthFromMonthNumber(yearMonth);
      else return yearMonth;
    } else {
      if(yearMonth.trim().length == 6) return covertYearMonthToReadableMonthYear(yearMonth);
      else return yearMonth
    }
  }

export const tableDataToJsonArray = (
    tableData: IDrillDownTableData, 
    response: { [key in string]: any }[] = [],
    higherOrderData: {key: string, value: string}[] = []
) => {
    tableData.data.forEach(record => {
        if(record.nestedHeaderKey != null 
            && typeof record.nestedHeaderKey == 'string'
            && record.nestedTable instanceof Object) {
                let highOrder = {
                    key: record.nestedHeaderKey as string,
                    value: record[record.nestedHeaderKey as string].value
                }
                tableDataToJsonArray(
                    record.nestedTable as IDrillDownTableData, 
                    response, [...higherOrderData, highOrder]
                );
        } else {
            let resp = {};
            higherOrderData?.forEach(highPair => {
                resp[highPair.key] = getReadableYearMonth(highPair.value || '', highPair.key)
            })
            tableData.headers.forEach(heading =>  {
                resp[heading.text] = record[heading.text].value
                if(['period', 'month'].includes(heading.text.toLowerCase().trim()))
                    resp[heading.text] = getReadableYearMonth(resp[heading.text] || '', heading.text)
                if(heading.type.toLowerCase().trim() == 'currency')
                    resp[heading.text] = resp[heading.text] ? `$${(+resp[heading.text] || 0).toFixed(2)}` : '-';
            });
            response.push(resp);
        }
    });
    return response;
}

export const exportTableToCsv = (tableData: IDrillDownTableData): Blob => {
    let csvData: string[] = [];
    let response = tableDataToJsonArray(tableData);
    let headers = [];
    csvData = response.map(record => {
        Object.keys(record).forEach(key => 
            !headers.includes(key) && headers.push(key)
        );
        return headers.map(key => record[key]).join(';');
    })
    let data = headers.join(';');
    csvData.forEach(record => {
        data += `\n${record}`;
    });
    return new Blob([data], { type: 'text/csv;charset=utf-8;' });
}

interface ITabelCellOptions {
    class?: string,
    spanClass?: string,
    rowspan?: number,
    colspan?: number,
    onClickFn?: Function,
    onHoverFn?: Function,
    isDirectory?: boolean
}

interface ITableColumnOptions {
    type?: string, 
    class?: string, 
    spanClass?: string,
    allowSort?: boolean,
    isRowspan?: boolean,
    isColspan?: boolean,
    allowFilter?: boolean,
    cellOption?: ITabelCellOptions
}

interface IJsonTableOptions {
    headers: { [key: string]: ITableColumnOptions & { alias?: string, hidden?: boolean } },
    orderConfig?: {
        headerKey: string,
        constraint: 'asc' | 'desc' | null
    },
    allowFilter?: boolean
}

interface ITableNestingConfiguration {
    [key: string]: {
        alias?: string,
        groupingFunction?: 'sum' | 'diff' | 'avg' | null ,
        class?: string, 
        spanClass?: string,
        allowSort?: boolean,
        isRowspan?: boolean,
        isColspan?: boolean,
        allowFilter?: boolean,
    }
}

interface ITableNestingSortingConfiguration {
    parentTableSort?: {
        headerKey: string,
        constraint: 'asc' | 'desc' | null
    },
    nestedTableSort?: {
        headerKey: string,
        constraint: 'asc' | 'desc' | null
    }
}