import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[prismDropdown]',
  standalone: true,
})
export class PrismDropdownDirective {
  constructor(private elementRef: ElementRef) {}

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    event.stopPropagation();
    this.handleDropdownState(event);
  }

  handleDropdownState(_: MouseEvent) {
    if (
      this.elementRef.nativeElement.parentElement.classList.contains('open')
    ) {
      this.elementRef.nativeElement.parentElement.classList.remove('open');
    } else {
      this.elementRef.nativeElement.parentElement.classList.add('open');
      this.setPosition();
    }
  }

  setPosition() {
    const rect =
      this.elementRef.nativeElement.nextSibling.getBoundingClientRect();
    const triggerRect = this.elementRef.nativeElement.getBoundingClientRect();
    if (triggerRect.top - rect.height + 20 < 0) {
      this.elementRef.nativeElement.nextSibling.style.top = `${triggerRect.bottom + 5}px`;
    } else if (triggerRect.bottom + rect.height + 20 > window.innerHeight) {
      this.elementRef.nativeElement.nextSibling.style.top = `${triggerRect.bottom - rect.height}px`;
    }

    if (triggerRect.left - rect.width + 20 < 0) {
      this.elementRef.nativeElement.nextSibling.style.left = `${triggerRect.right}px`;
    } else if (triggerRect.right + rect.width + 20 > window.innerWidth) {
      this.elementRef.nativeElement.nextSibling.style.left = `${triggerRect.right - rect.width}px`;
    }
  }
}
