import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  BUTTON_STYLES,
  PRIMS_BUTTON_STYLES,
} from '../../../constants/core-components/prism-button.style.constants';
import { NgIf } from '@angular/common';

@Component({
  selector: 'prism-button',
  templateUrl: './prism-button.component.html',
  styleUrls: ['./prism-button.component.scss'],
  imports: [NgIf],
  standalone: true,
})
export class PrismButtonComponent {
  @Input() buttonType!: string;
  @Input() name!: string;
  @Input() buttonStyle = BUTTON_STYLES.DEFAULT;
  @Input() additionalStyles?: string;
  @Input() disabled: boolean = false;
  @Input() showLoading: boolean = false;

  @Output() onClick: EventEmitter<Event> = new EventEmitter<Event>();
  onClicking(e: Event) {
    this.onClick.emit(e);
  }

  get buttonStyles(): string {
    return PRIMS_BUTTON_STYLES[this.buttonStyle];
  }
}
