export const PRIMS_BUTTON_STYLES = {
  DEFAULT: 'rounded-lg border border-primary-500 bg-primary-500 px-5 py-2.5 text-center font-medium text-white shadow-sm transition-all hover:border-primary-700 hover:bg-primary-700 focus:ring focus:ring-primary-200 disabled:cursor-not-allowed disabled:border-primary-300 disabled:bg-primary-300',
  PLAIN: 'rounded-lg border border-primary-100 bg-primary-100 px-5 py-2.5 text-center font-medium text-primary-600 transition-all hover:border-primary-200 hover:bg-primary-200 focus:ring focus:ring-primary-50 disabled:border-primary-50 disabled:bg-primary-50 disabled:text-primary-400 disabled:cursor-not-allowed',
  GHOST: 'rounded-lg border border-transparent bg-transparent px-5 py-2.5 text-center font-medium text-gray-700 shadow-none transition-all hover:bg-gray-100 disabled:bg-transparent disabled:text-gray-400',
  ICON: 'inline-flex items-center gap-1.5',
  GREEN: 'rounded-lg border border-green-500 bg-green-500 px-5 py-2.5 text-center font-medium text-white shadow-sm transition-all hover:border-green-700 hover:bg-green-700 focus:ring focus:ring-green-200 disabled:cursor-not-allowed disabled:border-green-300 disabled:bg-green-300',
  YELLOW: 'rounded-lg border border-yellow-500 bg-yellow-500 px-5 py-2.5 text-center font-medium text-white shadow-sm transition-all hover:border-yellow-700 hover:bg-yellow-700 focus:ring focus:ring-yellow-200 disabled:cursor-not-allowed disabled:border-yellow-300 disabled:bg-yellow-300',
  CYAN: 'rounded-lg border border-cyan-500 bg-cyan-500 px-5 py-2.5 text-center font-medium text-white shadow-sm transition-all hover:border-cyan-700 hover:bg-cyan-700 focus:ring focus:ring-cyan-200 disabled:cursor-not-allowed disabled:border-cyan-300 disabled:bg-cyan-300',
  DANGER: 'rounded-lg border border-red-500 bg-red-500 px-5 py-2.5 text-center font-medium text-white shadow-sm transition-all hover:border-red-700 hover:bg-red-700 focus:ring focus:ring-red-200 disabled:cursor-not-allowed disabled:border-red-300 disabled:bg-red-300',
  BORDER: 'rounded-lg border border-gray-300 bg-white px-5 py-2.5 text-center text-sm font-medium text-gray-700 shadow-sm transition-all hover:bg-gray-100 focus:ring focus:ring-gray-100 disabled:cursor-not-allowed disabled:border-gray-100 disabled:bg-gray-50 disabled:text-gray-400',
  PLAIN_GREEN: 'rounded-lg border border-green-100 bg-green-100 px-5 py-2.5 text-center font-medium text-green-600 transition-all hover:border-green-200 hover:bg-green-200 focus:ring focus:ring-green-50 disabled:border-green-50 disabled:bg-green-50 disabled:text-green-400',
  PLAIN_YELLOW: 'rounded-lg border border-yellow-100 bg-yellow-100 px-5 py-2.5 text-center font-medium text-yellow-600 transition-all hover:border-yellow-200 hover:bg-yellow-200 focus:ring focus:ring-yellow-50 disabled:border-yellow-50 disabled:bg-yellow-50 disabled:text-yellow-400',
  PLAIN_CYAN: 'rounded-lg border border-cyan-100 bg-cyan-100 px-5 py-2.5 text-center font-medium text-cyan-600 transition-all hover:border-cyan-200 hover:bg-cyan-200 focus:ring focus:ring-cyan-50 disabled:border-cyan-50 disabled:bg-cyan-50 disabled:text-cyan-400',
  PLAIN_DANGER: 'rounded-lg border border-red-100 bg-red-100 px-5 py-2.5 text-center font-medium text-red-600 transition-all hover:border-red-200 hover:bg-red-200 focus:ring focus:ring-red-50 disabled:border-red-50 disabled:bg-red-50 disabled:text-red-400',
  TAG_PLAIN: 'm-1 inline-flex justify-center items-center rounded-full border border-gray-300 text-black px-3 py-2 text-center text-sm font-medium transition-all hover:bg-gray-50 focus:ring focus:ring-gray-100 disabled:cursor-not-allowed disabled:border-gray-100 disabled:bg-gray-50 disabled:text-gray-400',
  TAG_BLUE: 'm-1 inline-flex justify-center items-center rounded-full border border-blue-300 text-black px-3 py-2 text-center text-sm font-medium transition-all hover:bg-blue-50 focus:ring focus:ring-blue-100 disabled:cursor-not-allowed disabled:border-gray-100 disabled:bg-gray-50 disabled:text-gray-400',
}

export enum BUTTON_STYLES {
  DEFAULT = 'DEFAULT',
  PLAIN = 'PLAIN',
  GHOST = 'GHOST',
  ICON = 'ICON',
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  CYAN = 'CYAN',
  DANGER = 'DANGER',
  BORDER = 'BORDER',
  PLAIN_GREEN = 'PLAIN_GREEN',
  PLAIN_YELLOW = 'PLAIN_YELLOW',
  PLAIN_CYAN = 'PLAIN_CYAN',
  PLAIN_DANGER = 'PLAIN_DANGER',
  TAG_PLAIN = 'TAG_PLAIN',
  TAG_BLUE= 'TAG_BLUE',
}

