import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { PrismDropdownDirective } from '../../../directives/prism-dropdown-directive/prism-dropdown.directive';
import { Subscription } from 'rxjs';

@Component({
  selector: 'prism-dropdown',
  templateUrl: './prism-dropdown.component.html',
  styleUrls: ['./prism-dropdown.component.scss'],
  standalone: true,
  imports: [PrismDropdownDirective],
})
export class PrismDropdownComponent implements AfterViewInit, OnDestroy {
  @ViewChild('prismDropDownContainer') dropdownContainer: ElementRef;
  @ViewChild('prismDropDownContent') prismDropDownContent: ElementRef;

  @Input() closeModal = new EventEmitter<Boolean>();
  subscription: Subscription[] = [];

  @HostListener('window:click', ['$event'])
  onWindowClick(_: MouseEvent) {
    this.dropdownContainer.nativeElement.classList.remove('open');
  }

  ngAfterViewInit() {
    this.subscription.push(
      this.closeModal.subscribe(event => {
        if(event) {
          this.dropdownContainer.nativeElement.classList.remove('open');
        }
      })
    );
    this.prismDropDownContent.nativeElement.addEventListener(
      'click',
      (e: MouseEvent) => {
        e.stopPropagation();
      },
    );
  }

  ngOnDestroy() {
    this.subscription.forEach(sub => sub.unsubscribe());
    this.prismDropDownContent.nativeElement.removeEventListener(
      'click',
      () => {},
    );
  }
}
