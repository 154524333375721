export enum USER_TYPES {
  PRESIDIO_POWER = 'PRESIDIO_POWER',
  PRESIDIO_STANDARD = 'PRESIDIO_STANDARD',
  PRESIDIO_FINANCE = 'PRESIDIO_FINANCE',
  CUSTOMER_POWER = 'CUSTOMER_POWER',
  CUSTOMER_STANDARD = 'CUSTOMER_STANDARD',
  CUSTOMER_FINANCE = 'CUSTOMER_FINANCE'
}

export enum USER_TYPES_FRIENDLY_NAMES {
  PRESIDIO_POWER = 'Presidio Power',
  PRESIDIO_STANDARD = 'Presidio Standard',
  PRESIDIO_FINANCE = 'Presidio Finance',
  CUSTOMER_POWER = 'Customer Power',
  CUSTOMER_STANDARD = 'Customer Standard',
  CUSTOMER_FINANCE = 'Customer Finance'
}
